export default {
  'memberAndPartner.name': 'Members and partners',
  'memberAndPartner.title': 'Import',
  'memberAndPartner.buttonSubmit': 'Add',
  'memberAndPartner.buttonEdit': 'Save',
  'fees.done': 'Complete',
  'fees.not-done': 'Unfinished',
  'historySponsors.view': 'Sponsor history',
  'historyFees.view': 'History of festivals',

  'memberAndPartner.info': 'Detail',
  'memberAndPartner.edit': 'Edit',
  'memberAndPartner.delete': 'Delete',
  'memberAndPartner.histoty-sponsors': 'Sponsor history',
  'memberAndPartner.histoty-cost': 'History of festivals',

  'memberAndPartner.active': 'Active',
  'memberAndPartner.not-active': 'Not active',
  'member.connectInfos.btn-add': 'Add the person in charge',

  // Executive Committee
  'executiveCommittee.title': 'Executive Committee',
  'executiveCommittee.heading': 'List of executives',
  'executiveCommittee.add': 'Add new executive committee',
  'executiveCommittee.code': 'Membership code',
  'executiveCommittee.name': 'Membership name',
  'executiveCommittee.emailAddress': 'Email',
  'executiveCommittee.workUnit': 'Unit name',
  'executiveCommittee.position': 'Position',
  'executiveCommittee.status': 'Activity status',
  'executiveCommittee.action': 'Act',
  'executiveCommittee.view': 'Executive Board details',
  'executiveCommittee.edit': 'Edit the Executive Committee',
  'executiveCommittee.delete.title': 'Delete the Executive Committee',
  'executiveCommittee.delete.content': 'Do you want to delete this executive?',
  'executiveCommittee.non-delete.title': 'Can not delete the executive committee',
  'executiveCommittee.non-delete.content': 'Can not delete the operating committee',

  'executiveCommittee.label.code': 'Membership code',
  'executiveCommittee.placeholder.code': 'Enter the member code',
  'executiveCommittee.label.idCard': 'ID Card',
  'executiveCommittee.placeholder.idCard': 'Enter the card code',
  'executiveCommittee.label.name': 'First and last name',
  'executiveCommittee.placeholder.name': 'Enter the full name',
  'executiveCommittee.label.dayOfBirth': 'Date of birth',
  'executiveCommittee.placeholder.dayOfBirth': 'Enter the date of birth',
  'executiveCommittee.label.gender': 'Gender',
  'executiveCommittee.gender.male': 'Male',
  'executiveCommittee.gender.female': 'Female',
  'executiveCommittee.label.phoneNumber': 'Phone number',
  'executiveCommittee.placeholder.phoneNumber': 'Enter your phone number',
  'executiveCommittee.label.emailAddress': 'Email',
  'executiveCommittee.placeholder.emailAddress': 'Enter your email address',
  'executiveCommittee.label.avatar': 'Avatar',
  'executiveCommittee.label.workUnit': 'Unit name',
  'executiveCommittee.placeholder.workUnit': 'Enter the name of the unit',
  'executiveCommittee.label.position': 'Position',
  'executiveCommittee.placeholder.position': 'Enter the position',
  'executiveCommittee.label.title': 'Title',
  'executiveCommittee.placeholder.title': 'Enter Title',
  'executiveCommittee.label.prorogue': 'Prorogue',
  'executiveCommittee.placeholder.prorogue': 'Enter the prorogue',
  'executiveCommittee.label.detailLogin': 'Login information',
  'executiveCommittee.placeholder.detailLogin': 'Membership code',
  'executiveCommittee.label.status': 'Activity status',
  'executiveCommittee.label.status.active': 'Active',
  'executiveCommittee.label.status.not-active': 'Not active',
  'executiveCommittee.label.structure': 'Structure',
  'executiveCommittee.placeholder.structure': 'Select the structure',
  'executiveCommittee.structure.executiveCommittee': 'Executive Committee',
  'executiveCommittee.structure.standingCommittee': 'Standing Committee',
  'executiveCommittee.validator.structure': 'Select the structure',

  // Form Executive Committee
  'executiveCommittee.header': 'Add the Executive Committee',
  'executiveCommittee.main.title-1': '1. Personal information',
  'executiveCommittee.main.title-2': '2. Unit information',
  'executiveCommittee.main.title-3': '3. Association position',
  'executiveCommittee.main.title-4': '5. Account information',
  'formPartner.main-title-9-personal': '9. Account information',

  // Member
  'member.title': 'Enterprise member',
  'member.heading': 'List of enterprise members',
  'member.code': 'Membership code',
  'member.enterpriseName': 'Membership name',
  'member.fieldId': 'Field',
  'member.memberFields': 'Field',

  'member.marketId': 'Market',
  'member.targetCustomer': 'Targeted customer',
  'member.enterpriseScale': 'Business size',
  'member.status': 'Activity status',
  'member.action': 'Act',
  'member.field': 'Field',
  'member.market': 'Market',
  'member.headOfficeAddress': 'Address',
  'member.type': 'Type of member',
  // 'member.targetcustomer': 'Target customers',
  'member.view': 'Membership details',
  'member.edit': 'Membership editing',
  'member.delete.title': 'Delete members',
  'member.delete.content': 'Do you want to delete this member?',
  'member.non-delete.title': 'Can not delete members',
  'member.non-delete.content': 'Unable to delete operating members',

  'member.add': 'Add new members',
  'member.label.code': 'Membership code',
  'member.placeholder.code': 'Enter the member code',
  'member.label.idCard': 'ID Card',
  'member.placeholder.idCard': 'Enter the card code',
  'member.label.enterpriseName': 'Business name (Vietnamese)',
  'member.placeholder.enterpriseName': 'Enter the name of the business',
  'member.label.enterpriseEnglishName': 'Business name (English)',
  'member.placeholder.enterpriseEnglishName': 'Enter the name of the business',
  'member.label.enterpriseAcronymsName': 'Business name (abbreviation)',
  'member.placeholder.enterpriseAcronymsName': 'Enter the name of the business',
  'member.label.headOfficeAddress': 'Address of headquarters',
  'member.placeholder.headOfficeAddress': 'Enter the address of the head office',
  'member.label.transactionOfficeAddress': 'Address of the transaction office',
  'member.placeholder.transactionOfficeAddress': 'Enter the address of the transaction office',
  'member.label.taxCode': 'Tax code',
  'member.placeholder.taxCode': 'Enter the tax code',
  'member.label.phoneNumber': 'Phone number',
  'member.placeholder.phoneNumber': 'Enter your phone number',
  'member.label.website': 'Website',
  'member.placeholder.website': 'Enter the website',
  'member.label.fanpage': 'Fanpage',
  'member.placeholder.fanpage': 'Enter Fanpage',
  'member.label.birthday': 'Founding',
  'member.placeholder.birthday': 'Enter the date of establishment',
  'member.label.charterCapital': 'Authorized capital',
  'member.placeholder.charterCapital': 'Enter the charter volley',
  'member.label.turnover': 'Revenue before joining',
  'member.placeholder.turnover': 'Revenue in 2021',
  'member.label.type': 'Type of member',
  'member.placeholder.type': 'Select the type of member',
  'member.label.fieldRelationIds': 'Field',
  'member.placeholder.fieldRelationIds': 'Choose field',
  'member.label.marketId': 'Market',
  'member.placeholder.marketId': 'Choose the market',
  'member.label.targetCustomerId': 'Targeted customer',
  'member.placeholder.targetCustomerId': 'Select target customers',
  'member.label.enterpriseScale': 'Business size',

  'member.enterpriseScale.value-0': '50 - 100 people',
  'member.enterpriseScale.value-1': '100 - 200 people',
  'member.enterpriseScale.value-2': '200 - 500 people',
  'member.enterpriseScale.value-3': 'Over 500 people',
  'member.label.certificateId': 'Certificate',
  'member.placeholder.certificateId': 'Select certificates',
  'member.label.prize': 'Prize',
  'member.placeholder.prize': 'Enter the prize',
  'member.label.certificateOfMerit': 'Merit',
  'member.placeholder.certificateOfMerit': 'Enter the Certificate of Merit, Certificate of Merit',
  'member.label.leaderInfo.name': 'First and last name',
  'member.placeholder.leaderInfo.name': 'Enter the full name',
  'member.label.leaderInfo.dateOfBirth': 'Date of birth',
  'member.placeholder.leaderInfo.dateOfBirth': 'Enter the date of birth',
  'member.label.leaderInfo.position': 'Position',
  'member.placeholder.leaderInfo.position': 'Enter the position',
  'member.label.leaderInfo.phoneNumber': 'Phone number',
  'member.placeholder.leaderInfo.phoneNumber': 'Enter your phone number',
  'member.label.leaderInfo.gender': 'Gender',
  'member.label.leaderInfo.gender.male': 'Male',
  'member.label.leaderInfo.gender.female': 'Female',
  'member.label.leaderInfo.emailAddress': 'Email contact directly',
  'member.placeholder.leaderInfo.emailAddress': 'Enter your email address',
  'member.label.connectInfos.name': 'First and last name',
  'member.placeholder.connectInfos.name': 'Enter the full name',
  'member.label.connectInfos.position': 'Position',
  'member.placeholder.connectInfos.position': 'Enter the position',
  'member.label.connectInfos.phoneNumber': 'Phone number',
  'member.placeholder.connectInfos.phoneNumber': 'Enter your phone number',
  'member.label.connectInfos.gender': 'Gender',
  'member.label.connectInfos.gender.male': 'Male',
  'member.label.connectInfos.gender.female': 'Female',
  'member.label.connectInfos.emailAddress': 'Email contact directly',
  'member.placeholder.connectInfos.emailAddress': 'Enter your email address',
  'member.label.detailSingin': 'Login information',
  'member.placeholder.detailSingin': 'Login information',
  'member.label.careers': 'Branch',
  'member.placeholder.careers': 'Choose industry',
  'member.placeholder.type.memberUnit': 'Unit member',
  'member.placeholder.type.memberLinked': 'Link members',
  'member.label.clubs': 'Club',
  'member.placeholder.clubs': 'Select clubs',

  // Form Member
  'formMember.header': 'Add units',
  'formMember.main.title-1': '1. Personal information',
  'formMember.main.title-2': '2. Industry - field',
  'formMember.main.title-3': '3. Market',
  'formMember.main.title-4': '4. Target customers',
  'formMember.main.title-5': '5. Business scale',
  'formMember.main.title-6': '6. Certificate and award',
  'formMember.main.title-7': '7. Unit leadership',
  'formMember.main.title-8': '8. In charge of connection',
  'formMember.main.title-9': '9. Club',
  'formMember.main.title-11': '11. Account information',
  'formMember.main.title-11.1': '10. Account information',

  // Partner
  'partner.title': 'Partner',
  'partner.heading': 'List of partners',
  'partner.code': 'Partner code',
  'partner.enterpriseName': 'Partner name',
  'partner.headOfficeAddress': 'Address',
  'partner.phoneNumber': 'Phone',
  'partner.organizationId': 'Organization',
  'partner.enterpriseId': 'Enterprise',
  'partner.status': 'Activity status',
  'partner.action': 'Act',
  'partner.view': 'Details partners',
  'partner.edit': 'Mainly repair partners',
  'partner.add': 'Add new partners',
  'partner.delete.title': 'Delete partners',
  'partner.delete.content': 'Do you want to delete partners?',
  'partner.non-delete.title': 'Can not delete partners',
  'partner.non-delete.content': 'Can not delete operating partners',
  'partner.partnerName': 'Partner name',
  'partner.type': 'Partner type',
  'partner.partnerSource': 'Type',
  'partner.enterprise': 'Enterprise partner',
  'partner.personal': 'Personal partner',
  'partner.vietnam': 'Vietnam',
  'partner.national': 'National',

  'partner.label.code': 'Partner code',
  'partner.placeholder.code': 'Enter the partner code',
  'partner.label.idCard': 'ID Card',
  'partner.placeholder.idCard': 'Enter the card code',
  'partner.label.enterpriseName': 'Business name (Vietnamese)',
  'partner.placeholder.enterpriseName': 'Enter the name of the business',
  'partner.label.enterpriseEnglishName': 'Business name (English)',
  'partner.placeholder.enterpriseEnglishName': 'Enter the name of the business',
  'partner.label.enterpriseAcronymsName': 'Business name (abbreviation)',
  'partner.placeholder.enterpriseAcronymsName': 'Enter the name of the business',
  'partner.label.partnerSource': 'Classify',
  'partner.partnerSource.domestic': 'Domestic',
  'partner.partnerSource.foreign': 'International',
  'partner.label.headOfficeAddress': 'Enter head office address',
  'partner.placeholder.headOfficeAddress': 'Enter the address',
  'partner.label.transactionOfficeAddress': 'Address of the transaction office',
  'partner.placeholder.transactionOfficeAddress': 'Enter the address of the transaction office',
  'partner.label.taxCode': 'Tax code',
  'partner.placeholder.taxCode': 'Enter the tax code',
  'partner.label.phoneNumber': 'Phone number',
  'partner.placeholder.phoneNumber': 'Enter your phone number',
  'partner.label.website': 'Website',
  'partner.placeholder.website': 'Enter the website',
  'partner.label.organizationId': 'Organization',
  'partner.placeholder.organizationId': 'Choose organization',
  'partner.label.enterpriseId': 'Enterprise',
  'partner.placeholder.enterpriseId': 'Choose business',
  'partner.label.leaderInfo.name': 'First and last name',
  'partner.placeholder.leaderInfo.name': 'Enter the full name',
  'partner.label.leaderInfo.dateOfBirth': 'Date of birth',
  'partner.placeholder.leaderInfo.dateOfBirth': 'Enter the date of birth',
  'partner.label.leaderInfo.position': 'Position',
  'partner.placeholder.leaderInfo.position': 'Enter the position',
  'partner.label.leaderInfo.phoneNumber': 'Phone number',
  'partner.placeholder.leaderInfo.phoneNumber': 'Enter your phone number',
  'partner.label.leaderInfo.gender': 'Gender',
  'partner.label.leaderInfo.gender.male': 'Male',
  'partner.label.leaderInfo.gender.female': 'Female',
  'partner.label.leaderInfo.emailAddress': 'Email contact directly',
  'partner.placeholder.leaderInfo.emailAddress': 'Enter your email address',
  'partner.label.connectInfos.name': 'First and last name',
  'partner.placeholder.connectInfos.name': 'Enter the full name',
  'partner.label.connectInfos.position': 'Position',
  'partner.placeholder.connectInfos.position': 'Position',
  'partner.label.connectInfos.phoneNumber': 'Phone number',
  'partner.placeholder.connectInfos.phoneNumber': 'Enter your phone number',
  'partner.label.connectInfos.gender': 'Gender',
  'partner.label.connectInfos.gender.male': 'Male',
  'partner.label.connectInfos.gender.female': 'Female',
  'partner.label.connectInfos.emailAddress': 'Email contact directly',
  'partner.placeholder.connectInfos.emailAddress': 'Enter your email address',
  'partner.label.type': 'Type',
  'partner.type.enterprise': 'Enterprise Partner',
  'partner.type.personal': 'Personal Partner',
  'partner.label.name': 'Fullname',
  'partner.placeholder.name': 'Enter fullname',
  'partner.label.position': 'Position',
  'partner.placeholder.position': 'Enter Position',
  'partner.label.emailAddress': 'Email',
  'partner.placeholder.emailAddress': 'Enter email',
  'partner.label.workUnit': 'Work unit',
  'partner.placeholder.workUnit': 'Enter work unit',

  // Form Partner
  'formPartner.header': 'Add partners',
  'formPartner.main-title': 'Partner type',
  'formPartner.main-title-1': '1. Enterprise infomation',
  'formPartner.main-title-1-personal': '1. Personal infomation',

  'formPartner.main-title-2': '2. Organizations and businesses',
  'formPartner.main-title-2-personal': '2. Branch and Field',

  'formPartner.main-title-3': '3. Unit leadership',
  'formPartner.main-title-4': '4. In charge of connection',
  'formPartner.main-title-5': '5. Account information',
  'formPartner.main-title-5-personal': '3. Account information',

  // History Fees
  'hitory-fees.name': "Member's name",
  'hitory-fees.email': 'Email',
  'hitory-fees.operationStatus': 'Activity status',
  'hitory-fees.total': 'The fees are closed',
  'hitory-fees.year': 'Five',
  'hitory-fees.payDate': 'Festival date',
  'hitory-fees.paid': 'The money must paid (VND)',
  'hitory-fees.paymentContent': 'Content',
  'hitory-fees.userReceive': 'Receiver',
  'hitory-fees.status': 'Status',
  'hitory-fees.attachPath': 'Attach file',
  'fees.status.param': '{status,select, 1 {Comlete} 2 {Incomplete } other {Không xác định}}',
  'history-fees.total': 'Total membership fee paid',

  // History Sponsors
  'history-sponsors.sponsorDate': 'Date of contribution',
  'history-sponsors.content': 'Content',
  'history-sponsors.product': 'Product contribution',
  'history-sponsors.unitPrice': 'Unit',
  'history-sponsors.quantity': 'Amount',
  'history-sponsors.intoMoney': 'Money (VND)',
  'history-sponsors.user': 'Receiver',
  'history-sponsors.attachPath': 'Attach file',
  'history-sponsors.total': 'Total contribution',

  // Member Personal
  'memberPersonal.title': 'Personal member',
  'memberPersonal.heading': 'List of personal members',
  'memberPersonal.view': 'Detail of personal member',
  'memberPersonal.add': 'Add personal member',
  'memberPersonal.edit': 'Edit personal member',
  'formMemberPersonal.main.title-1': '1. Personal infomation',
  'memberPersonal.label.code': 'Login code',
  'memberPersonal.placeholder.code': 'Enter login code',
  'memberPersonal.label.idCard': 'Id card',
  'memberPersonal.placeholder.idCard': 'Enter id card',
  'memberPersonal.label.name': 'Full name',
  'memberPersonal.placeholder.name': 'Enter full name',
  'memberPersonal.label.position': 'Position',
  'memberPersonal.placeholder.position': 'Enter position',
  'memberPersonal.label.dayOfBirth': 'Birthday',
  'memberPersonal.placeholder.dayOfBirth': 'Enter Birthday',
  'memberPersonal.label.gender': 'Gender',
  'memberPersonal.label.gender.male': 'Male',
  'memberPersonal.label.gender.female': 'Female',
  'memberPersonal.label.phoneNumber': 'Phonenumber',
  'memberPersonal.placeholder.phoneNumber': 'Enter phonenumber',
  'memberPersonal.label.emailAddress': 'Email',
  'memberPersonal.placeholder.emailAddress': 'Enter email address',
  'formMemberPersonal.main.title-2': '2. Careers - Fields',
  'memberPersonal.label.careers': 'Career',
  'memberPersonal.placeholder.careers': 'Select careers',
  'memberPersonal.label.fieldRelationIds': 'Field',
  'memberPersonal.placeholder.fieldRelationIds': 'Select fields',
  'formMemberPersonal.main.title-3': '3. Infomation',
  'memberPersonal.label.detailSingin': 'Login code',
  'member-personal.field': 'Field',

  // Member personal table
  'member-personal.code': 'Member code',
  'member-personal.name': 'Fullname',
  'member-personal.emailAddress': 'Email',
  'member-personal.career': 'Career',
  'member-personal.action': 'Action',

  'member.provinces': 'Select province',
  'member.districts': 'Select district',
  'member.communes': 'Select commune',

  'member.label.dayOfBirth': 'Enter day of birthday',
  'member.label.headOfficeAddressRoad': 'Address',
  'member.label.transactionOfficeAddressRoad': 'Address',
  'formMember.main.title-1-unit': '1. Unit Information',

  'member.label.emailAddress': 'Email',
  'formPartner.main-title-4-personal': '4. Account information',
  'formPartner.main-title-4.1-personal': '3. Account information',
  'formPartner.main-title-3-personal': '3. Executive committees',
  'member-personal.yes': 'Yes',
  'member-personal.no': 'No',
  'executiveCommittee.label.canConfirmActivity': 'Permission for attendance',
  // update on 10/10/2023
  'memberPersonal.label.workUnit': 'Work unit',
  // update on 08/08/2024
  'member.label.rank': 'Membership Rank',
  'member.placeholder.rank': 'Please select a membership rank',
  'member.label.fee': 'Membership Fee',
  'member.label.period': 'Participation Period',
  'member.label.period-1': 'Period 1',
  'member.label.period-2': 'Period 2',
  'formMember.main.title-10': '10. Rank and Membership Fee',
  'formMember.main.title-10.1': '9. Rank and Membership Fee',
  'executiveCommittee.main.title-5': '4. Rank', ///
};
