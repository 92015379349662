import React from 'react';

import Icon from '@ant-design/icons';

const participantIcon = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="33" viewBox="0 0 40 33" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.75826 2.97117C8.75826 2.1317 8.07773 1.45117 7.23826 1.45117C6.39879 1.45117 5.71826 2.1317 5.71826 2.97117V6.15039H2.53514C1.69566 6.15039 1.01514 6.83092 1.01514 7.67039C1.01514 8.50986 1.69566 9.19039 2.53514 9.19039H5.71826V12.3762C5.71826 13.2156 6.39879 13.8962 7.23826 13.8962C8.07773 13.8962 8.75826 13.2156 8.75826 12.3762V9.19039H11.9401C12.7796 9.19039 13.4601 8.50986 13.4601 7.67039C13.4601 6.83092 12.7796 6.15039 11.9401 6.15039H8.75826V2.97117ZM30.1938 29.0617V32.1982H8.23826V29.0617C8.23826 29.0617 8.23826 22.7887 19.216 22.7887C30.1938 22.7887 30.1938 29.0617 30.1938 29.0617ZM24.7049 14.1633C24.7049 13.0777 24.383 12.0164 23.7799 11.1138C23.1768 10.2112 22.3195 9.50764 21.3165 9.0922C20.3136 8.67676 19.2099 8.56806 18.1452 8.77985C17.0805 8.99164 16.1024 9.5144 15.3348 10.282C14.5672 11.0497 14.0444 12.0277 13.8326 13.0924C13.6208 14.1572 13.7295 15.2608 14.145 16.2638C14.5604 17.2667 15.2639 18.124 16.1666 18.7271C17.0692 19.3302 18.1304 19.6522 19.216 19.6522C20.6718 19.6522 22.0679 19.0739 23.0973 18.0445C24.1266 17.0151 24.7049 15.619 24.7049 14.1633ZM30.0997 22.7887C31.0638 23.5348 31.8527 24.4831 32.4108 25.5668C32.969 26.6506 33.2828 27.8435 33.3303 29.0617V32.1982H39.6033V29.0617C39.6033 29.0617 39.6033 23.3689 30.0997 22.7887ZM28.6256 8.67438C27.5461 8.66837 26.4904 8.9911 25.5988 9.59965C26.5515 10.9307 27.0637 12.5265 27.0637 14.1633C27.0637 15.8001 26.5515 17.3959 25.5988 18.7269C26.4904 19.3354 27.5461 19.6582 28.6256 19.6522C30.0813 19.6522 31.4774 19.0739 32.5068 18.0445C33.5362 17.0151 34.1145 15.619 34.1145 14.1633C34.1145 12.7075 33.5362 11.3114 32.5068 10.282C31.4774 9.25267 30.0813 8.67438 28.6256 8.67438Z"
        fill="url(#icon2)"
      />
      <defs>
        <linearGradient
          id="icon2"
          x1="1.01514"
          y1="25.2801"
          x2="32.9753"
          y2="-5.44364"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#13F1FC" />
          <stop offset="1" stop-color="#0470DC" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default participantIcon;
