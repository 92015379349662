import React from 'react';
import * as Icon from 'react-feather';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';
import { routerApproveMember } from '@view/ApproveMember/router';
import { routerMemberAndPartnerTabs } from '@view/MemberAndPartner/router';

export const routerMemberAndPartnerApproveMember: IRouter = {
  path: null,
  exact: true,
  name: 'memberAndPartner.name', //translate here for breadcrumb and sidebar
  menu: {
    // generatePath: () => 'member-and-partner/checkPermission', //default url
    icon: <Icon.Users />,
    activePath: 'member-and-partner',
  },

  routes: [routerMemberAndPartnerTabs, routerApproveMember],
};
