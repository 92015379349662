export default {
  // Calendar
  'calendar.title': 'Calendar',
  'calendar.today': 'Today',

  // Meeting Schedulex
  'calendar.meeting-schedule': 'Meeting schedule',
  'calendar.modal-meeting-schedule.more-attendees': 'More attendees',
  'calendar.meeting-schedule.add': 'Add a new meeting schedule',
  'calendar.meeting-schedule.update': 'Update meeting schedule',
  'calendar.meeting-schedule.update.cancellation': 'Cancellation Schedule',
  'calendar.meeting-schedule.info': 'Meeting schedule details',
  'calendar.meeting-schedule.cancel': 'Want to cancel this meeting?',
  'calendar.meeting-schedule.form.host': 'Host',
  'calendar.meeting-schedule.form.title': 'Title',
  'calendar.meeting-schedule.form.content': 'Contents of the meeting',
  'calendar.meeting-schedule.form.content.placeholder': 'Please enter content',
  'calendar.meeting-schedule.form.address': 'Address',
  'calendar.meeting-schedule.form.start.time': 'Start time',
  'calendar.meeting-schedule.form.end.time': 'End time',
  'calendar.meeting-schedule.participants': 'Participants',
  'calendar.meeting-schedule.status': 'Status',
  'meeting-schedule-pageInfo.status.id':
    '{status, select, 1 {Have not happened yet} 2 {Happenning} 3 {Finished} 4 {Cancelled} other {}}',
  'meeting-schedule-pageInfo.table.code': 'Membership code',
  'meeting-schedule-pageInfo.table.name': 'Member name',
  'meeting-schedule-pageInfo.table.emailAddress': 'Email',
  'meeting-schedule-pageInfo.table.memberFields': 'Field',
  'meeting-schedule-pageInfo.table.type': 'Member Type',
  'meeting-schedule-pageInfo.table.memberMarkets': 'Market',
  'meeting-schedule-pageInfo.table.memberTargetCustomers': 'Targeted customer',
  'meeting-schedule-pageInfo.table.enterpriseScale': 'Enterprise scale',
  'meeting-schedule-pageInfo.table.confirm': 'Confirm participation',
  'meeting-schedule-pageInfo.table.confirm.id':
    '{status, select, 2 {Participation} 3 {Not participate} 1 {Not participate yet} other {}}',
  // Event Calendar
  'calendar.event.calendar': 'Event calendar',
  'calendar.event.calendar.add': 'Add new events',
  'calendar.event.calendar.update': 'Event Update',
  'calendar.event.calendar.info': 'Event details',
};
