import { custom as listperMission } from './code';

export const beKeyToFeKey = (beKey?: string[]): string[] => {
  if (!beKey) {
    return [];
  }
  if (listperMission == null || listperMission.data == null) {
    return beKey;
  }

  const defaultKey: string[] = [...beKey];
  return listperMission.data.reduce((feKey, permissionValue) => {
    const { FE_KEY, BE_KEY, TYPE } = permissionValue;
    switch (TYPE) {
      case 'AND':
        if (BE_KEY.every(key => beKey.includes(key))) {
          feKey.push(FE_KEY);
        }
        break;
      case 'OR':
        if (BE_KEY.some(key => beKey.includes(key))) {
          feKey.push(FE_KEY);
        }
        break;
    }
    return feKey;
  }, defaultKey);
};
