export default {
  'homepage.name': 'Home',
  'common.empty': ' ',
  'common.file': 'File',
  'common.file-check': 'Upload file',
  'common.save': 'Save',
  'common.cancel': 'Cancel',
  'common.home': 'Home page',
  'common.display': 'Display',
  'common.entries': 'goods in each page',
  'common.form.required': 'Please enter',
  'common.delete': 'Delete',
  'common.share': 'Share',
  'common.listen': 'To listen',
  'common.statusActive': 'Active',
  'common.add': 'Add',
  'common.edit': 'Edit',
  'common.all': 'All',
  'common.keyword': 'Key word',
  'common.statusDeactive': 'Stop operation',
  'common.button.accept': 'Confirm',
  'common.button.resend.link': 'Resend Link',
  'common.button.return': 'Come back',
  'link.return.login': 'Back to the login page',
  'common.valid.required': 'Please fill out',
  'common.valid.whitespace': 'Please do not enter white space',
  'common.valid.email': 'Must be in the format',
  'common.deviceType': 'Type of device',
  'common.email': 'Email address',
  'common.volumn': 'Set up volume',
  'common.powerOn': 'Open source',
  'common.powerOff': 'Power off',
  'common.refresh': 'Restart',
  'common.update': 'Update',
  'common.continue': 'Continue',
  'common.management': 'Manage',
  'common.formNote': 'Are mandatory information schools',
  'common.active': 'Active',
  'common.deactive': 'Stop operation',
  'common.status': 'Status',
  'common.statusNotApproval': 'Not approval',
  'common.statusReject': 'Inactive',
  'common.invalid': 'Malformed.',
  'common.input.placeholder': 'Please enter {label}',
  'common.status.param': '{status,select, 0 {Unproven} 1 {Active} 2 {Inactive} other {Unknown}}',
  'common.status.new': 'Not active',
  'common.status.approval': 'Active',
  'common.approval': 'Approve',
  'common.file-back': 'Recall',
  'common.back': 'Come back',
  'common.statusConnect': 'Connecting',
  'common.statusDisconnect': 'Lost connection',
  'common.StatusNewsletterPlaying': 'Being a newsletter',
  'common.StatusNewsletterStop': 'Stop the newsletter',
  'common.goBack': 'Return',
  'common.required.password': 'Please enter a password',
  'common.info': 'Information',
  'dashboard.area': 'Locality',
  'dashboard.radio_device': 'Radio equipment',
  'dashboard.mtc_device': 'Public electronics',
  'dashboard.audio_news': 'Radio newsletter',
  'dashboard.mtc_news': 'Public electronic newsletter',
  'area.placeholder': 'Please enter the name of the area',
  'common.remove': 'Erase',
  'common.play': 'Play',
  'common.stt': 'No.',
  'common.detail': 'Detail',
  'common.non.title': 'Action',
  'common.turnOn': 'On',
  'common.turnOff': 'Turn off',
  'common.pause': 'Stop playing',
  'common.minus': 'Erase',
  'common.convert': 'Convert',
  'common.using': 'Using',
  'common.notUse': 'Not yet used',
  'common.check': 'Save',
  'common.close': 'Close',
  'common.noti': 'Notify',
  'common.viewPlus': 'See more',
  'common.return': 'Come back',
  'common.search.placeholder': 'Enter keywords to search',
  'common.eye': 'Display',
  'common.eyeOff': 'Hide',
  'common.hidden': 'Not displayed',
  'common.in.process': 'Uploading',
  'common.logout': 'Logout',
  'common.logOut': 'Logout',
  'common.logout.title': 'Confirm logout',
  'common.logout.content': 'Do you really want to log out?',
  'common.change.password': 'Change password',
  'common.report': 'Report',
  'common.page': 'Page',
  'common.answers': 'Answers',
  'common.search': 'Search',
  'common.action': 'Action',
  'common.code.status': 'Code status',
  'common.name': 'Full name',
  'common.times': 'Sponsor date',
  'common.block': 'Inactive',
  'common.upload.title': 'Click or drag file to this area to upload',
  'common.upload.content': 'Support for a single or bulk upload.',
  'common.upload.content.one': 'Support for a single upload.',
  'common.exportFile': 'Export file',
  'common.upload': 'Upload',
  'common.viewFile': 'View attachments',
  'common.ok': 'Agree',
  'common.add.member.list': 'Add member list',
  'common.undefined': 'Undefined',
  'common.table-result': 'matching results',
  'activities.time': 'Time',
  'activities.startDay': 'From the day',
  'activities.endDay': 'To the day',
  'common.vnd': 'VND',
  'common.approve': 'Note',
  'common.change-field': 'Latest changes',
};
