import React from 'react';

import Icon from '@ant-design/icons';

const nonParticipantSvg = () => (
  <svg
    width={162}
    height={128}
    viewBox="0 0 162 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.94194 1.97145C4.52626 -0.612878 8.71628 -0.612878 11.3006 1.97145L21.1003 11.7711L30.8902 1.98121C33.4745 -0.603113 37.6645 -0.603113 40.2489 1.98121C42.8332 4.56554 42.8332 8.75556 40.2489 11.3399L30.4589 21.1298L40.254 30.9248C42.8383 33.5092 42.8383 37.6992 40.254 40.2835C37.6697 42.8678 33.4797 42.8678 30.8953 40.2835L21.1003 30.4885L11.2955 40.2933C8.71113 42.8776 4.52111 42.8776 1.93678 40.2933C-0.647546 37.709 -0.647546 33.5189 1.93678 30.9346L11.7416 21.1298L1.94194 11.3301C-0.64239 8.74579 -0.64239 4.55577 1.94194 1.97145ZM121.035 114.253V127.908H25.4474V114.253C25.4474 114.253 25.4474 86.9419 73.2411 86.9419C121.035 86.9419 121.035 114.253 121.035 114.253ZM97.1379 49.3898C97.1379 44.6634 95.7364 40.0432 93.1106 36.1134C90.4847 32.1836 86.7526 29.1207 82.386 27.312C78.0194 25.5033 73.2146 25.03 68.579 25.9521C63.9435 26.8742 59.6855 29.1501 56.3435 32.4922C53.0014 35.8342 50.7255 40.0922 49.8034 44.7277C48.8814 49.3633 49.3546 54.1681 51.1633 58.5347C52.972 62.9012 56.0349 66.6334 59.9647 69.2592C63.8945 71.8851 68.5147 73.2866 73.2411 73.2866C79.5789 73.2866 85.6572 70.7689 90.1387 66.2874C94.6202 61.8058 97.1379 55.7276 97.1379 49.3898ZM120.625 86.9419C124.822 90.1901 128.257 94.3187 130.687 99.0372C133.117 103.756 134.483 108.949 134.69 114.253V127.908H162.001V114.253C162.001 114.253 162.001 89.4681 120.625 86.9419ZM114.207 25.4929C109.507 25.4668 104.911 26.8718 101.03 29.5213C105.177 35.3161 107.407 42.2636 107.407 49.3898C107.407 56.5159 105.177 63.4634 101.03 69.2583C104.911 71.9077 109.507 73.3127 114.207 73.2866C120.545 73.2866 126.623 70.7689 131.105 66.2874C135.586 61.8058 138.104 55.7276 138.104 49.3898C138.104 43.0519 135.586 36.9737 131.105 32.4922C126.623 28.0106 120.545 25.4929 114.207 25.4929Z"
      fill="white"
      fillOpacity="0.2"
    />
  </svg>
);

const nonParticipantLargeIcon = props => <Icon component={nonParticipantSvg} {...props} />;
export default nonParticipantLargeIcon;
