import React from 'react';

import Icon from '@ant-design/icons';

const participantSvg = () => (
  <svg
    width={168}
    height={134}
    viewBox="0 0 168 134"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.7103 6.6625C33.7103 3.00771 30.7475 0.0449219 27.0927 0.0449219C23.4379 0.0449219 20.4751 3.00771 20.4751 6.6625V20.5078H6.61709C2.9623 20.5078 -0.000488281 23.4706 -0.000488281 27.1254C-0.000488281 30.7802 2.9623 33.743 6.61709 33.743H20.4751V47.6088C20.4751 51.2636 23.4379 54.2264 27.0927 54.2264C30.7475 54.2264 33.7103 51.2636 33.7103 47.6088V33.743H47.5634C51.2182 33.743 54.181 30.7802 54.181 27.1254C54.181 23.4706 51.2182 20.5078 47.5634 20.5078H33.7103V6.6625ZM127.034 120.253V133.908H31.4472V120.253C31.4472 120.253 31.4472 92.9419 79.2408 92.9419C127.034 92.9419 127.034 120.253 127.034 120.253ZM103.138 55.3898C103.138 50.6634 101.736 46.0432 99.1103 42.1134C96.4845 38.1836 92.7523 35.1207 88.3857 33.312C84.0191 31.5033 79.2143 31.03 74.5788 31.9521C69.9432 32.8742 65.6852 35.1501 62.3432 38.4922C59.0012 41.8342 56.7252 46.0922 55.8032 50.7277C54.8811 55.3633 55.3543 60.1681 57.163 64.5347C58.9717 68.9012 62.0346 72.6334 65.9644 75.2592C69.8943 77.8851 74.5145 79.2866 79.2408 79.2866C85.5786 79.2866 91.6569 76.7689 96.1384 72.2874C100.62 67.8058 103.138 61.7276 103.138 55.3898ZM126.625 92.9419C130.822 96.1902 134.257 100.319 136.687 105.037C139.117 109.756 140.483 114.949 140.69 120.253V133.908H168V120.253C168 120.253 168 95.4681 126.625 92.9419ZM120.207 31.4929C115.507 31.4668 110.911 32.8718 107.029 35.5213C111.177 41.3162 113.407 48.2636 113.407 55.3898C113.407 62.5159 111.177 69.4634 107.029 75.2583C110.911 77.9077 115.507 79.3128 120.207 79.2866C126.545 79.2866 132.623 76.7689 137.104 72.2874C141.586 67.8058 144.104 61.7276 144.104 55.3898C144.104 49.0519 141.586 42.9737 137.104 38.4922C132.623 34.0106 126.545 31.4929 120.207 31.4929Z"
      fill="white"
      fillOpacity="0.2"
    />
  </svg>
);
const participantLargeIcon = props => <Icon component={participantSvg} {...props} />;
export default participantLargeIcon;
