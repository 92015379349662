export default {
  'approveMember.sideBar': 'Modification Request List',
  'approveMember.code': 'Member Code',
  'approveMember.name': 'Member Name',
  'approveMember.clubId': 'Club',
  'approveMember.target': 'Target',
  'approveMember.dateReceive': 'Request Date',
  'approveMember.status': 'Status',
  'approveMember.approve': 'Accept',
  'approveMember.replied': 'Replied',
  'approveMember.pending': 'Pending',
  'approveMember.status.param':
    '{status,select, 1 {Pending} 2 {Accept} 3 {Replied} other {Unknown}}',
  'approveMember.before': 'Details Before Modification',
  'approveMember.after': 'Details After Modification',
  'approveMember.modal.title': 'Approve',
  'approveMember.modal.send': 'Send',
  'approveMember.modal.content': 'Confirm approval of the selected changes',
  'approveMember.modal.note.title': 'Unsuitable Modification Information',
  'approveMember.modal.item.label': 'Content',
  'approveMember.modal.noted': 'Please check the status of the selected requests',
  'approveMember.rejectReason': 'Note',
};
