export default {
  'memberRank.name-sidebar': 'Member Rank',
  'memberRank.list': 'Member Rank List',
  'memberRank.name': 'Member Rank Name',
  'memberRank.desc': 'Description',
  'memberRank.fee': 'Membership Fee/Year (VND)',
  'memberRank.sponsor': 'Minimum Sponsorship (VND)',
  'memberRank.delete': 'Delete Member Rank',
  'memberRank.add': 'Add Member Rank',
  'memberRank.view': 'View Member Rank Details',
  'memberRank.edit': 'Edit Member Rank',
  'memberRank.label.fee': 'Membership Fee',
  'memberRank.label.name': 'Rank Name',
  'memberRank.fee.suffix': 'VND/year',

  // update 8/8
  'memberRank.code': 'Code',
  'memberRank.edit-breadcrumb': 'Edit information',
};
