import React from 'react';

import { IRouter } from '@routers/interface';

export const routerResultSearchPage: IRouter = {
  path: '/clubs/result-search-page',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'clubs.result-search-page', //translate here for breadcrumb and sidebar
};
