import React from 'react';

import { IRouter } from '@routers/interface';

export const routerLogin: IRouter = {
  path: '/login',
  loader: React.lazy(() => import('./index')),
  exact: true,
  masterLayout: false,
};

export const routerLoginHome: IRouter = {
  path: '/',
  loader: React.lazy(() => import('./index')),
  exact: true,
  masterLayout: false,
};
