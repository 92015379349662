import React from 'react';

import Icon from '@ant-design/icons';

const listModificationIconSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2837_33466)">
      <path
        d="M8 14H10"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 10.9478H14"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 3.75H18.75C18.9489 3.75 19.1397 3.82902 19.2803 3.96967C19.421 4.11032 19.5 4.30109 19.5 4.5V10M9 3.75H5.25C5.05109 3.75 4.86032 3.82902 4.71967 3.96967C4.57902 4.11032 4.5 4.30109 4.5 4.5V20.25C4.5 20.4489 4.57902 20.6397 4.71967 20.7803C4.86032 20.921 5.05109 21 5.25 21H10"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.25 6.75V6C8.25 5.00544 8.64509 4.05161 9.34835 3.34835C10.0516 2.64509 11.0054 2.25 12 2.25C12.9946 2.25 13.9484 2.64509 14.6517 3.34835C15.3549 4.05161 15.75 5.00544 15.75 6V6.75H8.25Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.8477 15.125V17.8037L19.0507 19.143"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.9336 16.4642H22.1366V14.2319"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.1729 21.3753C19.4801 22.0377 18.6099 22.4786 17.6712 22.6428C16.7325 22.807 15.7669 22.6873 14.895 22.2987C14.023 21.91 13.2835 21.2696 12.7687 20.4574C12.2539 19.6453 11.9867 18.6974 12.0005 17.7323C12.0143 16.7672 12.3085 15.8276 12.8463 15.0309C13.3841 14.2342 14.1417 13.6158 15.0244 13.2529C15.9071 12.89 16.8757 12.7987 17.8093 12.9904C18.7429 13.1822 19.6001 13.6484 20.2737 14.3309C20.9219 14.9961 21.4572 15.6234 22.1336 16.4644"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2837_33466">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const ListModificationIcon = props => <Icon component={listModificationIconSvg} {...props} />;
export default ListModificationIcon;
