export default {
  'targetCustomers.create': 'Thêm khách hàng mục tiêu',
  'targetCustomers.code': 'Mã khách hàng mục tiêu',
  'targetCustomers.name': 'Tên khách hàng mục tiêu',
  'targetCustomers.description': 'Mô tả',
  'targetCustomers.update': 'Chỉnh sửa khách hàng mục tiêu',
  'targetCustomers.information': 'Thông tin khách hàng mục tiêu',
  'targetCustomers.createdAt': 'Thời gian tạo',
  'targetCustomers.action': 'Hành động',
  'targetCustomers.confirm.title.delete': 'Bạn có muốn xóa những khách hàng mục tiêu này?',
  'targetCustomers.confirm.content.delete': 'Bạn vui lòng xác nhận lại',
};
