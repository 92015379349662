import { Popover } from 'antd';
import React from 'react';

import { iconDot } from '@assets/images';
import CONFIG from '@config';

const IFrame = () => {
  const content = (
    <iframe
      src={`${CONFIG.LOGIN_PAGE}application-iframe`}
      width="100%"
      height="100%"
      frameBorder={0}
    />
  );
  // const [open, setOpen] = useState(false);

  // const hide = () => {
  //   setOpen(false);
  // };

  // const handleOpenChange = (newOpen: boolean) => {
  //   console.debug('handleOpenChange  newOpen:', newOpen);
  //   setOpen(newOpen);
  // };
  return (
    <div style={{ position: 'relative' }}>
      <Popover
        content={content}
        trigger="click"
        overlayClassName="ifame-popover"
        // open={open}
        // onOpenChange={handleOpenChange}
        getPopupContainer={trigger => trigger.parentElement!}
        placement="bottomRight"
        showArrow={false}
      >
        <img src={iconDot} alt="" className="icon-dot" />
      </Popover>
    </div>
  );
};

export default React.memo(IFrame);
