import React from 'react';
import * as Icon from 'react-feather';

import { IRouter } from '@routers/interface';

export const routerNews: IRouter = {
  path: '/news',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'news.nameSidebar', //translate here for breadcrumb and sidebar
  menu: {
    icon: <Icon.Layout />,
  },
  // permissionCode: PermissionCode.CLUBS_SEARCH,
};

export const routerNewsForm: IRouter = {
  path: '/news/:type/:id',
  loader: React.lazy(() => import('./component/FormNews/FormNews')),
  exact: true,
  name: 'news.nameSidebar', //translate here for breadcrumb and sidebar
  widthFull: true,
  // permissionCode: PermissionCode.CLUBS_SEARCH,
};

export const routerNewsFormAdd: IRouter = {
  path: '/news/:type',
  loader: React.lazy(() => import('./component/FormNews/FormNews')),
  exact: true,
  name: 'news.nameSidebar', //translate here for breadcrumb and sidebar
  widthFull: true,
  // permissionCode: PermissionCode.CLUBS_SEARCH,
};
