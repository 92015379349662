import React from 'react';

import { IRouter } from '@routers/interface';

export const routerHistoryFees: IRouter = {
  path: '/member-and-partner/history-fees/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'historyFees.view', //translate here for breadcrumb and sidebar
};
