import React from 'react';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerListClubsMemberTab: IRouter = {
  path: '/clubs/list-members/:tab/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  // generatePath: () => 'clubs/list-members/checkPermission', //default url

  // menu: {
  //   activePath: 'clubs/list-members',
  //   generatePath: () => 'clubs/list-members/checkPermission', //default url
  // },
  name: 'clubs.list-members', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.CLUBS_VIEW,
};
