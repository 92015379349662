import React from 'react';

import Icon from '@ant-design/icons';

const listMemberIconSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2837_33487)">
      <path
        d="M8 14H10"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 10.9478H14"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 3.75H18.75C18.9489 3.75 19.1397 3.82902 19.2803 3.96967C19.421 4.11032 19.5 4.30109 19.5 4.5V10M9 3.75H5.25C5.05109 3.75 4.86032 3.82902 4.71967 3.96967C4.57902 4.11032 4.5 4.30109 4.5 4.5V20.25C4.5 20.4489 4.57902 20.6397 4.71967 20.7803C4.86032 20.921 5.05109 21 5.25 21H10"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.25 6.75V6C8.25 5.00544 8.64509 4.05161 9.34835 3.34835C10.0516 2.64509 11.0054 2.25 12 2.25C12.9946 2.25 13.9484 2.64509 14.6517 3.34835C15.3549 4.05161 15.75 5.00544 15.75 6V6.75H8.25Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="17.5" cy="14.5" r="2.5" stroke="currentColor" stroke-width="1.5" />
      <path
        d="M22 24V21C22 19.8954 21.1046 19 20 19H15C13.8954 19 13 19.8954 13 21V24"
        stroke="currentColor"
        stroke-width="1.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_2837_33487">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const ListMemberIcon = props => <Icon component={listMemberIconSvg} {...props} />;
export default ListMemberIcon;
