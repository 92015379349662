import React from 'react';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerFormEvent: IRouter = {
  path: '/event/:type/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  // name: 'formAction.info', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.ACTIVITIES_VIEW_FORM,
};
