import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { imgAvatar } from '@assets/images';
import { RootState } from '@modules';
import UserEntity from '@modules/user/entity';
import { Selector } from '@reduxjs/toolkit';
import ChangeLanguage from '@shared/components/ChangeLanguage';

import Iframe from './Iframe';

interface IHeaderComponent {
  profile?: UserEntity;
}

const HeaderComponentSelector: Selector<RootState, IHeaderComponent> = (state: RootState) => {
  return {
    profile: state.profile.user,
  };
};

const HeaderComponent = () => {
  const { profile } = useSelector(HeaderComponentSelector);
  const navigate = useNavigate();

  return (
    <div className="header-component" id="header">
      <div className="header-component__search">
        <ChangeLanguage />
      </div>
      <Iframe />
      <div
        className="header-component__dropdown"
        onClick={() => {
          navigate('/profile');
        }}
      >
        <div className="dropdown__profile__img">
          <img alt="img-avatar" className="img-avatar" src={profile?.avatarPath || imgAvatar} />
        </div>
      </div>
      <div
        onClick={() => {
          navigate('/profile');
        }}
        className="header-component__identify"
      >
        <h4 className="identify__admin">{profile?.username}</h4>
        <p className="identify__hi">{(profile && profile?.role?.name) || 'Unknown'}</p>
      </div>
    </div>
  );
};

export default memo(HeaderComponent);
