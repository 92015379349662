export default {
  'enterprises.create': 'Thêm doanh nghiệp',
  'enterprises.code': 'Mã doanh nghiệp',
  'enterprises.name': 'Tên doanh nghiệp',
  'enterprises.description': 'Mô tả',
  'enterprises.update': 'Chỉnh sửa doanh nghiệp',
  'enterprises.information': 'Thông tin doanh nghiệp',
  'enterprises.createdAt': 'Thời gian tạo',
  'enterprises.action': 'Hành động',
  'enterprises.confirm.title.delete': 'Bạn có muốn xóa những doanh nghiệp này?',
  'enterprises.confirm.content.delete': 'Bạn vui lòng xác nhận lại',
};
