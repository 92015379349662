import React from 'react';
import * as Icon from 'react-feather';

import { IRouter } from '@routers/interface';

export const routerHome: IRouter = {
  path: '/files',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'home.name', //translate here for breadcrumb and sidebar
  menu: {
    icon: <Icon.FileText />,
  },
};
