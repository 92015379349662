export default {
  'markets.create': 'Add market',
  'markets.code': 'Market code',
  'markets.name': 'Market name',
  'markets.description': 'Describe',
  'markets.update': 'Market editing',
  'markets.information': 'Market information',
  'markets.createdAt': 'Creation time',
  'markets.action': 'Act',
  'markets.confirm.title.delete': 'Do you want to delete these markets?',
  'markets.confirm.content.delete': 'Please confirm',
};
