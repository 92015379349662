export default {
  'homepage.name': 'Trang chủ',
  'common.empty': ' ',
  'common.file': 'Tệp',
  'common.file-check': 'Tải tệp lên',
  'common.action': 'Hành động',
  'common.save': 'Lưu',
  'common.cancel': 'Hủy',
  'common.home': 'Trang chủ',
  'common.display': 'Hiển thị',
  'common.entries': 'hàng trong mỗi trang',
  'common.form.required': 'Nhập',
  'common.delete': 'Xóa',
  'common.share': 'Chia sẻ',
  'common.listen': 'Nghe',
  'common.statusActive': 'Đang hoạt động',
  'common.add': 'Thêm mới',
  'common.addSchool': 'Đăng ký trường',
  'common.edit': 'Chỉnh Sửa',
  'common.all': 'Tất cả',
  'common.keyword': 'Từ khóa',
  'common.statusDeactive': 'Ngưng hoạt động',
  'common.button.accept': 'Xác nhận',
  'common.button.resend.link': 'Gửi lại link',
  'common.button.return': 'Quay lại',
  'link.return.login': 'Quay lại trang đăng nhập',
  'common.answers': 'Danh sách đáp án',
  'common.valid.required': 'Điền',
  'common.valid.whitespace': 'Không nhập khoảng trắng',
  'common.valid.email': 'Phải đúng định dạng',
  'common.deviceType': 'Loại thiết bị',
  'common.exportFile': 'Xuất file',

  'common.email': 'Địa chỉ email',

  'common.volumn': 'Điều chỉnh âm lượng',
  'common.powerOn': 'Mở nguồn',
  'common.powerOff': 'Tắt nguồn',
  'common.refresh': 'Khởi động lại',
  'common.update': 'Cập nhật',
  'common.continue': 'Tiếp tục',
  'common.management': 'Quản lý',
  'common.formNote': 'Là những trường thông tin bắt buộc',
  'common.active': 'Đang hoạt động',
  'common.deactive': 'Ngưng hoạt động',
  'common.status': 'Trạng thái',
  'common.statusNotApproval': 'Chưa duyệt',
  'common.statusReject': 'Từ chối',
  'common.invalid': 'không đúng định dạng.',
  'common.input.placeholder': 'Nhập {label}',
  'common.status.param':
    '{status,select, 2 {Ngưng hoạt động} 1 {Đang hoạt động} other {Không xác định}}',
  'common.status.new': 'Chưa phê duyệt',
  'common.status.approval': 'Đã phê duyệt',
  'common.approval': 'Phê duyệt',
  'common.file-back': 'Tải file xuống',
  'common.back': 'Quay lại',
  'common.statusConnect': 'Đang kết nối',
  'common.statusDisconnect': 'Mất kết nối',
  'common.StatusNewsletterPlaying': 'Đang phát bản tin',
  'common.StatusNewsletterStop': 'Ngưng phát bản tin',
  'common.goBack': 'Trở Về',
  'common.required.password': 'Nhập mật khẩu',
  'common.info': 'Thông tin',
  'dashboard.area': 'Địa bàn',
  'dashboard.radio_device': 'Thiết bị phát thanh',
  'dashboard.mtc_device': 'Thiết bị điện tử công cộng',
  'dashboard.audio_news': 'Bản tin phát thanh',
  'dashboard.mtc_news': 'Bản tin điện tử công cộng',
  'area.placeholder': 'Nhập tên địa bàn',
  'common.remove': 'Xóa',
  'common.play': 'Phát',
  'common.stt': 'STT',
  'common.detail': 'Chi tiết',
  'common.non.title': 'Hành động',
  'common.turnOn': 'Bật',
  'common.turnOff': 'Tắt',
  'common.minus': 'Xóa',
  'common.pause': 'Ngưng phát',
  'common.convert': 'Chuyển đổi',
  'common.using': 'Đang sử dụng',
  'common.notUse': 'Chưa sử dụng',
  'common.check': 'Lưu',
  'common.close': 'Đóng',
  'common.noti': 'Thông báo',
  'common.viewPlus': 'Xem thêm',
  'common.return': 'Quay lại',
  'common.search.placeholder': 'Nhập từ khóa để tìm kiếm',
  'common.eye': 'Hiển thị',
  'common.eyeOff': 'Ẩn',
  'common.hidden': 'Không hiển thị',
  'common.in.process': 'Đang tải lên',
  'common.logout': 'Đăng xuất',
  'common.logOut': 'Đăng xuất',
  'common.logout.title': 'Xác nhận đăng xuất',
  'common.logout.content': 'Bạn có thực sự muốn đăng xuất không?',
  'common.change.password': 'Đổi mật khẩu',
  'common.report': 'Báo cáo',
  'common.page': 'Trang',
  'common.search': 'Tìm kiếm',
  'common.block': 'Ngưng hoạt động',
  'common.code.status': 'Trạng thái mã',
  'common.used': 'Đã sử dụng',
  'common.unuse': 'Chưa sử dụng',
  'common.name': 'Họ và tên',
  'common.times': 'Ngày tài trợ',
  'common.luckyDraw': 'Quay giải',
  'common.luckyDraw.title': 'Lỗi quay giải thưởng',
  'common.luckyDraw.content': 'Chọn giải thưởng',
  'common.luckyDraw.param':
    'Xin chúc mừng chủ nhân số điện thoại {phoneNumber} đã trúng giải {name}. Với mã dự thưởng {code}',
  'common.luckyDraw.title.ok': 'Quay giải thành công',
  'common.upload.title': 'Nhấp hoặc kéo tệp vào khu vực này để tải lên',
  'common.upload.content': 'Hỗ trợ tải lên một lần hoặc hàng loạt.',
  'common.upload.content.one': 'Hỗ trợ tải lên một lần.',
  'common.download': 'Tải về',
  'common.upload': 'Tải lên',
  'common.viewFile': 'Xem file đính kèm',
  'common.ok': 'Đồng ý',
  'common.add.member.list': 'Thêm danh sách hội viên',
  'common.undefined': 'Không xác định',
  'activities.time': 'Thời gian',
  'activities.startDay': 'Từ ngày',
  'activities.endDay': 'Đến ngày',
  'common.table-result': 'kết quả phù hợp',
  'common.vnd': 'VNĐ',
  'common.approve': 'Ghi chú',
  'common.change-field': 'Thông tin sửa đổi mới nhất',
};
