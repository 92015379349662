export default {
  'clubs.title': 'Câu lạc bộ',
  'clubs.name': 'Câu lạc bộ',
  'clubs.heading': 'Danh sách câu lạc bộ',
  'clubs.code': 'Mã câu lạc bộ',
  'clubs.enterpriseName': 'Tên câu lạc bộ',
  'clubs.fieldId': 'Lĩnh vực',
  'clubs.marketId': 'Thị trường',
  'clubs.targetCustomer': 'Khách hàng mục tiêu',
  'clubs.enterpriseScale': 'Quy mô doanh nghiệp',
  'clubs.status': 'Tình trạng hoạt động',
  'clubs.action': 'Hành động',
  'clubs.amountMember': 'Số lượng thành viên',
  'clubs.field': 'Lĩnh vực',
  'clubs.market': 'Thị trường',
  'clubs.list-members': 'Danh sách hội viên',
  'clubs.status.param':
    '{status,select, 1 {Đang hoạt động} 2 {Ngưng hoạt động} other {Không xác định}}',
  // 'clubs.targetCustomer': 'Khách hàng mục tiêu',
  'clubs.view': 'Chi tiết câu lạc bộ',
  'clubs.edit': 'Chỉnh sửa câu lạc bộ',
  'clubs.delete.title': 'Xóa câu lạc bộ',
  'clubs.delete.content': 'Bạn có muốn xóa câu lạc bộ này?',
  'clubs.non-delete.title': 'Không thể xóa câu lạc bộ',
  'clubs.non-delete.content': 'Không thể xóa câu lạc bộ đang hoạt động',

  'clubs.memberFields': 'Lĩnh vực',

  'clubs.add': 'Thêm câu lạc bộ mới',
  'clubs.label.code': 'Mã câu lạc bộ',
  'clubs.placeholder.code': 'Nhập mã Câu lạc bộ',
  'clubs.label.represent': 'Người đại diện',
  'clubs.placeholder.represent': 'Nhập tên người đại diện',
  'clubs.label.name': 'Tên câu lạc bộ',
  'clubs.placeholder.name': 'Nhập tên câu lạc bộ',
  'clubs.label.idCard': 'Mã thẻ',
  'clubs.placeholder.idCard': 'Nhập Mã thẻ',
  'clubs.label.enterpriseName': 'Tên Tiếng Việt',
  'clubs.placeholder.enterpriseName': ' Nhập tên Tiếng Việt',
  'clubs.label.enterpriseEnglishName': 'Tên Tiếng Anh',
  'clubs.placeholder.enterpriseEnglishName': ' Nhập tên Tiếng Anh',
  'clubs.label.enterpriseAcronymsName': 'Tên viết tắt',
  'clubs.placeholder.enterpriseAcronymsName': ' Nhập tên viết tắt',
  'clubs.label.headOfficeAddress': 'Địa chỉ',
  'clubs.placeholder.headOfficeAddress': 'Nhập địa chỉ',
  'clubs.label.transactionOfficeAddress': 'Địa chỉ',
  'clubs.placeholder.transactionOfficeAddress': 'Nhập địa chỉ',
  'clubs.label.emailAddress': 'Email',
  'clubs.placeholder.emailAddress': 'Nhập địa chỉ email',
  'clubs.label.establishmentDecision': 'Quyết định thành lập',
  'clubs.placeholder.establishmentDecision': 'Nhập quyết định thành lập',
  'clubs.label.taxCode': 'Mã số thuế',
  'clubs.placeholder.taxCode': 'Nhập mã số thuế',
  'clubs.label.phoneNumber': 'Số điện thoại',
  'clubs.placeholder.phoneNumber': 'Nhập số điện thoại',
  'clubs.label.website': 'Website',
  'clubs.placeholder.website': 'Nhập website',
  'clubs.label.fanpage': 'Fanpage',
  'clubs.placeholder.fanpage': 'Nhập fanpage',
  'clubs.label.birthday': 'Ngày thành lập',
  'clubs.placeholder.birthday': 'Nhập ngày thành lập',
  'clubs.label.charterCapital': 'Vốn điều lệ',
  'clubs.placeholder.charterCapital': 'Nhập số vôn điều lệ',
  'clubs.label.turnover': 'Doanh thu trước khi gia nhập',
  'clubs.placeholder.turnover': 'Nhập doanh thu năm 2021',
  'clubs.label.type': 'Ngành',
  'clubs.placeholder.type': 'Chọn ngành',
  'clubs.label.fieldRelationIds': 'Lĩnh vực',
  'clubs.placeholder.fieldRelationIds': 'Chọn lĩnh vực',
  'clubs.label.marketId': 'Thị trường',
  'clubs.placeholder.marketId': 'Chọn thị trường',
  'clubs.label.targetCustomerId': 'Khách hàng mục tiêu',
  'clubs.placeholder.targetCustomerId': 'Chọn khách hàng mục tiêu',
  'clubs.enterpriseScale.value-0': '50 - 100 người',
  'clubs.enterpriseScale.value-1': '100 - 200 người',
  'clubs.enterpriseScale.value-2': '200 - 500 người',
  'clubs.enterpriseScale.value-3': 'Trên 500 người',
  'clubs.label.certificateId': 'Chứng chỉ',
  'clubs.placeholder.certificateId': 'Chọn chứng chỉ',
  'clubs.label.prize': 'Giải thưởng',
  'clubs.placeholder.prize': 'Nhập giải thưởng',
  'clubs.label.certificateOfMerit': 'Bằng khen, giấy khen',
  'clubs.placeholder.certificateOfMerit': 'Nhập bằng khen, giấy khen',
  'clubs.label.leaderInfo.name': 'Họ và tên',
  'clubs.placeholder.leaderInfo.name': 'Nhập họ và tên',
  'clubs.label.leaderInfo.dateOfBirth': 'Ngày sinh',
  'clubs.placeholder.leaderInfo.dateOfBirth': 'Nhập ngày sinh',
  'clubs.label.leaderInfo.position': 'Chức vụ',
  'clubs.placeholder.leaderInfo.position': 'Nhập chức vụ',
  'clubs.label.leaderInfo.phoneNumber': 'Số điện thoại',
  'clubs.placeholder.leaderInfo.phoneNumber': 'Nhập số điện thoại',
  'clubs.label.leaderInfo.gender': 'Giới tính',
  'clubs.label.leaderInfo.gender.male': 'Nam',
  'clubs.label.leaderInfo.gender.female': 'Nữ',
  'clubs.label.leaderInfo.emailAddress': 'Email liên hệ trực tiếp',
  'clubs.placeholder.leaderInfo.emailAddress': 'Nhập địa chỉ email',
  'clubs.label.connectInfos.name': 'Họ và tên',
  'clubs.placeholder.connectInfos.name': 'Nhập họ và tên',
  'clubs.label.connectInfos.position': 'Chức vụ',
  'clubs.placeholder.connectInfos.position': 'Nhập chức vụ',
  'clubs.label.connectInfos.phoneNumber': 'Số điện thoại',
  'clubs.placeholder.connectInfos.phoneNumber': 'Nhập số điện thoại',
  'clubs.label.connectInfos.gender': 'Giới tính',
  'clubs.label.connectInfos.gender.male': 'Nam',
  'clubs.label.connectInfos.gender.female': 'Nữ',
  'clubs.label.connectInfos.emailAddress': 'Email liên hệ trực tiếp',
  'clubs.placeholder.connectInfos.emailAddress': 'Nhập địa chỉ email',
  'clubs.label.detailSingin': 'Thông tin đăng nhập',
  'clubs.placeholder.detailSingin': 'Thông tin đăng nhập',
  'clubs.label.careers': 'Ngành',
  'clubs.placeholder.careers': 'Chọn ngành',
  'clubs.member': 'Danh sách hội viên',
  'clubs.ExecutiveCommittee': 'Danh sách ban điều hành',

  // Form Member
  'formClubs.header': 'Thêm câu lạc bộ đơn vị',
  'formClubs.main.title-1': '1. Thông tin cơ bản',
  'formClubs.main.title-2': '2. Ngành lĩnh vực',
  'formClubs.main.title-3': '3. Thị trường',
  'formClubs.main.title-4': '4. Phụ trách kết nối',
  'formClubs.main.title-5': '5. Thông tin câu lạc bộ',

  // Executive commitee
  'clubs.list-executive-committee': 'Danh sách ban điều hành',
  'clubs.executive-committees': 'Ban điều hành',
  'clubs.executive-committee.enterpriseName': 'Họ và tên',
  'clubs.executive-committee.action': 'Hành động',
  'clubs.executive-committees.view': 'Chi tiết ban điều hành',
  'clubs.executive-committees.add': 'Thêm mới ban điều hành',
  'clubs.executive-committees.edit': 'Chỉnh sửa ban điều hành',
  'clubs.executive-committees.main.title-1': '1. Thông tin cá nhân',
  'clubs.executive-committees.label.code': 'Mã ban điều hành',
  'clubs.executive-committees.placeholder.code': 'Nhập mã Ban điều hành',
  'clubs.executive-committees.label.name': 'Họ và tên',
  'clubs.executive-committees.placeholder.name': 'Nhập họ và tên',
  'clubs.executive-committees.label.gender': 'Giới tính',
  'clubs.executive-committees.gender.male': 'Nam',
  'clubs.executive-committees.gender.female': 'Nữ',
  'clubs.executive-committees.label.dayOfBirth': 'Ngày sinh',
  'clubs.executive-committees.placeholder.dayOfBirth': 'Nhập ngày sinh',
  'clubs.executive-committees.label.phoneNumber': 'Số điện thoại',
  'clubs.executive-committees.placeholder.phoneNumber': 'Nhập số điện thoại',
  'clubs.executive-committees.label.workUnit': 'Đơn vị',
  'clubs.executive-committees.placeholder.workUnit': 'Nhập đơn vị',
  'clubs.executive-committees.label.position': 'Chức vụ',
  'clubs.executive-committees.placeholder.position': 'Nhập chức vụ',
  'clubs.executive-committees.main.title-2': '2. Về Câu lạc bộ',
  'clubs.executive-committees.label.title': 'Chức danh câu lạc bộ',
  'clubs.executive-committees.placeholder.title': 'Nhập chức danh câu lạc bộ',
  'clubs.executive-committees.label.prorogues': 'Nhiệm kỳ',
  'clubs.executive-committees.placeholder.prorogues': 'Nhập nhiệm kỳ',
  'clubs.executive-committees.main.title-3': '3. Thông tin câu lạc bộ',
  'clubs.executive-committees.label.status': 'Tình trạng hoạt động',
  'clubs.executive-committees.label.status.active': 'Đang hoạt động',
  'clubs.executive-committees.label.status.not-active': 'Ngưng hoạt động',
  'clubs.executive-committee.name': 'Họ và tên',
  'clubs.executive-committee.prorogue': 'Nhiệm kỳ',
  'clubs.executive-committee.position': 'Chức vụ',
  'formClubsMember.main.title-9': '9. Thông tin hội viên',
  'clubs.label.dayOfBirth': 'Ngày thành lập',
  'clubs.placeholder.dayOfBirth': 'Nhập ngày thành lập',
  'clubs.executive-committee.delete': 'Xóa điều hành',
  'clubs.executive-committee.delete-content': 'Bạn có muốn xóa ban điều hành này?',

  // update on 09/10/2023
  'clubs.searhModal.title': 'Tìm kiếm hội viên',
  'clubs.search-label': 'Từ khóa',
  'clubs.search': 'Tìm',
  'clubs.result-search': 'Không tìm thấy hội viên.',
  'clubs.result-search-page': 'Kết quả tìm kiếm',
  'clubs.result-search-page.name': 'Tên hội viên',
};
