export default {
  'home.name': 'Documents',
  'home.title': 'Archives',
  'home.fileName': 'File name',
  'home.fullPath': 'Path',
  'home.contentType': 'File type',
  'home.createdAt': 'Date created',
  'home.confirm.title.delete': 'Delete the file',
  'home.confirm.content.delete': 'Do you agree to delete these files?',
  'home.create': 'Add new file',
  'home.action': 'Action',
  'home.memberSelect': 'Members participating',
  'home.memberSelect-placeholder': 'Select members participating',
  'home.accepts': 'Members participating',
  'home.upload': 'Upload file',
  'home.clubs': 'Club',
  'home.formAdd.code': 'Member code',
  'home.formAdd.enterpriseName': 'Club',
  'home.formAdd.emailAddress': 'Email',
  'home.formAdd.type': 'Member type',
  'home.formAdd.name': 'Member name',
  'home.maxFile': 'Unable to add more than 1 file at the same time',
  'home.requiredFile': 'Chose upload file',
  'dashboard.name': 'Dashboard',
  'home.input.name': 'File name',
  'home.placeholder.input.name': 'Enter file name',
  'home.validate-file': 'Only one file can be uploaded, and its size must not exceed 60MB.',
  'home.upload.content': 'Only one file upload is supported',
};
