export default {
  'Mes.Users.Password.InValid': 'Mật khẩu hoặc tên đăng nhập không hợp lệ',
  'Mes.Users.Username.NotFound': 'Mật khẩu hoặc tên đăng nhập không hợp lệ',
  'Mes.Users.SignIn.Successfully': 'Đăng nhập thành công',
  'Mes.Users.Create.Successfully': 'Tạo người dùng thành công',
  'Mes.Users.Update.Successfully': 'Cập nhật người dùng thành công',
  'Mes.Users.Delete.Successfully': 'Xóa người dùng thành công',
  'Mes.Permissions.Code.Required': 'Chưa chọn vai trò',
  'Mes.Roles.Create.Successfully': 'Tạo vai trò thành công',
  'Mes.Roles.Delete.Successfully': 'Xóa vai trò thành công',
  'Mes.Roles.Update.Successfully': 'Cập nhật vai trò thành công',
  'Mes.Roles.Name.Required': 'Tên vai trò không hợp lệ',
  'Mes.LuckyDraws.Public.Successfully': 'Công bố thành công',
  'Mes.LuckyDraws.Id.InValid': 'Công bố thất bại',
  'Mes.Users.Name.Required': 'Họ và tên không hợp lệ',
  'Mes.Users.Username.Required': 'Tên người dùng không hợp lệ',
  'Mes.Users.IsLocked': 'Người dùng đã bị khóa',
  'Mes.RedeemCodes.Update.Successfully': 'Cập nhật trạng thái thành công',
};
