export default {
  'certificates.create': 'Add certificates',
  'certificates.code': 'Code',
  'certificates.name': 'Certificate name',
  'certificates.description': 'Describe',
  'certificates.update': 'Certificate editing',
  'certificates.information': 'Certificate information',
  'certificates.createdAt': 'Creation time',
  'certificates.action': 'Act',
  'certificates.confirm.title.delete': 'Do you want to delete these certificates?',
  'certificates.confirm.content.delete': 'Please confirm',
};
