export default {
  'event.status.param':
    '{status,select, 1 {Chưa diễn ra} 2 {Đang diễn ra} 3 {Đã hết thúc} other {Không xác định}}',
  'event.name': 'Sự kiện',
  'event.listAction': 'Danh sách sự kiện',
  'event.add': 'Thêm sự kiện',
  'event.buttonSubmit': 'Thêm',
  'event.buttonEdit': 'Cập nhật',
  'event.time': 'Thời gian',
  'event.startDay': 'Từ ngày',
  'event.endDay': 'Đến ngày',
  'event.view': 'Chi tiết sự kiện',
  'event.edit': 'Cập nhật sự kiện',
  'event.btnViewMember': 'Danh sách Hội viên',
  'event.listMember': 'Danh sách Hội viên',
  'event.totalMember': 'Tổng số người',
  'event.totalMemberActive': 'Số lượng người tham gia',
  'event.totalMemberNotActive': 'Số lượng người không tham gia',
  'event.title': 'Tên sự kiện',
  'event.timeAction': 'Thời gian sự kiện',
  'event.content': 'Nội dung sự kiện',
  'event.meritBadgeIds': 'Chuyên hiệu',
  'event.score': 'Điểm',
  'event.clubIds': 'Đối tượng được tham gia',
  'event.validatorTitle': 'Tên sự kiện không được để trống',
  'event.validatorContent': 'Nội dung sự kiện không được để trống',
  'event.startTime': 'Thời gian bắt đầu',
  'event.endTime': 'Thời gian kết thúc',
  'event.creator': 'Người tạo',
  'event.status': 'Trạng thái',
  'event.totalMemberTable': 'Số người tham gia',
  'event.allMember': 'Tất cả Liên đội',
  'event.delete.title': 'Xóa sự kiện',
  'event.delete.content': 'Bạn có muốn xóa sự kiện này?',
  'event.non-delete.title': 'Không thể xóa',
  'event.non-delete.content': 'Không thể xóa sự kiện đã diễn ra',
  'event.validatorTime': 'Thời gian bắt đầu phải lớn hơn thời gian hiện tại',
  'actionChild.code': 'Mã Hội viên',
  'actionChild.name': 'Tên Hội viên',
  'actionChild.emailAddress': 'Email',
  'actionChild.register': 'Trang thái đăng ký',
  'event.grade': 'Khối',
  'event.unionOfDistrict': 'Từ Quận đội',
  'event.detachment': 'Từ Liên đội',
  'event.createSource': 'Nguồn',
  'event.tableChild.title': 'Tên sự kiện con',
  'event.tableChild.meritBadgeIds': 'Chuyên hiệu',
  'event.tableChild.score': 'Điểm',
  'event.tableChild.startTime': 'Thời gian bắt đầu',
  'event.tableChild.endTime': 'Thời gian kết thúc',
  'event.tableChild.grades': 'Khối',
  'event.tableChild.status': 'Trạng thái',
  'actionChild.status.param':
    '{status,select, 1 {Chưa xác nhận} 2 {Tham gia} 3 {Không tham gia} other {Không xác định}}',
  'actionChild.confirm': 'Trạng thái tham gia',
  'event.workunits': 'Chi đội',
  'actionChild.confirmTime': 'Thời gian tham gia',
  'event.list-member': 'Danh sách Hội viên',
  'event.scoreCollected': 'Số điểm nhận được',
  'event.scoreDay': 'Ngày tích điểm',
  'laudatory.modalHistory': 'Lịch sử tích điểm',
  'event.address': 'Địa điểm',
  'event.attach': 'File đính kèm',
  'event.upload.title': 'Chọn file đính kèm',
  ////////////// add for HCA
  'event.action': 'Hành động',
  'event.executiveCommittee': 'Ban chấp hành',
  'event.memberUnit': 'Hội viên chính thức',
  'event.linked': 'Hội viên liên kết',
  'event.memberPersonal': 'Hội viên cá nhân',
  'event.partnerEnterprise': 'Đối tác doanh nghiệp',
  'event.partnerPersonal': 'Đối tác cá nhân',
  'event.member': 'Đối tượng',
  'event.maxFile': 'Không thể thêm nhiều hơn cùng lúc nhiều hơn 3 file',
  'event.emailTemplate.title': 'Email Template',
  'event.emailTemplate.choose': 'Chọn template',
  'event.emailTemplate.add': 'Tạo mới',
  'event.emailTemplate.reset': 'Tải lại',
  'event.joinObjectHas': 'Hiện có ',
  'event.joinObject': 'đối tượng tham gia',
  'event.listObject': 'Chọn danh sách đối tượng',
  'event.listObject.view': 'Xem chi tiết danh sách hội viên',
  'event.landingPage.link': 'Link',
  'event.landingPage.preview': 'Truy cập Landing Page',
  'event.landingPage.add': 'Tạo Landing Page',
  'event.landingPage.update': 'Cập nhật Landing Page',
  'event.designLandingPage': 'Thiết kế landing Page',
  'event.designLandingPage.subtitle': 'Vui lòng chọn m ẫu Landing Page bạn muốn sử dụng',
  'event.method': 'Cách thức gửi vé',
  'event.method.label': 'Chọn cách gửi vé',
  'event.email.repeat': 'Gửi Email nhắc lại',
  'event.email.label.repeat': 'Nhắc lại trước',
  'event.email.label.no-repeat': 'Không nhắc lại',
  'event.emailRepeat.day': 'Chọn ngày',
  'event.day': 'Ngày',
  'event.info': 'Chi tiết sự kiện',
  'event.meetingMembers': 'Số người đã mời',
  'event.participantCount': 'Số người đăng ký tham gia',
  'event.method-1': 'Gửi vé ngay khi tạo sự kiện thành công',
  'event.method-2': 'Gửi vé sau khi đăng ký sự kiện',
  'event.detail.invited': 'Đối tượng đã chọn',
  'event.detail.subscriber': 'Đối tượng đã đăng ký',
  'event.detail.heading': 'Danh sách người tham gia',
  'actionChild.registerStatus': 'Trạng thái đăng ký',
  'actionChild.qr': 'Mã QR',
  'event.totalRegister': 'Tổng số người đăng ký',
  'actionChild.registrationPeriod': 'Thời gian đăng ký',
  'actionChild.qrCode': 'Mã QR',
  'activities.executiveCommittee': 'Ban chấp hành',
  'activities.memberUnit': 'Hội viên chính thức',
  'activities.linked': 'Hội viên liên kết',
  'activities.memberPersonal': 'Hội viên cá nhân',
  'activities.partnerEnterprise': 'Đối tác doanh nghiệp',
  'activities.partnerPersonal': 'Đối tác cá nhân',
  'activities.member': 'Đối tượng',
  'event.banner': 'Banner Email gửi vé',
  'event.error.empty': 'Chọn banner email gửi vé',
  'event.type.member.1': 'Hội viên chính thức',
  'event.type.member.2': 'Hội viên liên kết',
  'event.type.member.3': 'Đối tác doanh nghiệp',
  'event.type.member.4': 'Ban chấp hành',
  'event.type.member.5': 'Câu lạc bộ',
  'event.type.member.6': 'Hội viên cá nhân',
  'event.type.member.7': 'Đối tác cá nhân',
  'event.listMember.title.pronouns': 'Danh xưng',
  'event.listMember.title.companyName': 'Tên công ty',
  'event.listMember.title.jobTitle': 'Chức danh',
  'event.listMember.title.organizationType': 'Loại hình hoạt động',
  'event.listMember.title.businessField': 'Lĩnh vực hoạt động',
  'event.listMember.pronouns.1': 'Ông',
  'event.listMember.pronouns.2': 'Bà',
  'event.listMember.businessField.1': 'Công nghệ Thông tin – Truyền thông',
  'event.listMember.businessField.2': 'Giao thông vận tải',
  'event.listMember.businessField.3': 'Giáo dục – Đào tạo',
  'event.listMember.businessField.4': 'Y tế',
  'event.listMember.businessField.5': 'Du lịch',
  'event.listMember.businessField.6': 'Bán lẻ',
  'event.listMember.businessField.7': 'F&B',
  'event.listMember.businessField.8': 'Giải trí',
  'event.listMember.businessField.9': 'Thời trang',
  'event.listMember.businessField.10': 'Logistics',
  'event.listMember.businessField.11': 'Công nghiệp – Khai thác – Sản xuất – Gia công – Chế biến',
  'event.listMember.businessField.12': 'Tài chính – Ngân hàng – Bảo hiểm',
  'event.listMember.businessField.13': 'Bất động sản – Xây dựng',
  'event.listMember.businessField.14': 'Nông nghiệp – Nông nghiệp công nghệ cao',
  'event.listMember.businessField.15': 'Tài nguyên – Môi trường',
  'event.listMember.businessField.16': 'Khác',
  'event.listMember.organizationType.1': 'Cơ quan nhà nước/ Hiệp hội trong nước',
  'event.listMember.organizationType.2': 'Ngoại giao đoàn/ Tổ chức Quốc tế',
  'event.listMember.organizationType.3': 'Tổ chức phi chính phủ/ Tổ chức phi lợi nhuận',
  'event.listMember.organizationType.4': 'Công ty/ Nhà máy/ Xưởng sản xuất',
  'event.listMember.organizationType.5': 'Công ty thương mại/ dịch vụ',
  'event.listMember.organizationType.6': 'Viện nghiên cứu/ Trường học',
  'event.listMember.organizationType.7': 'Truyền thông/ báo chí',
  'event.listMember.organizationType.8': 'Chuyên gia',
  'event.listMember.organizationType.9': 'Làm việc tự do',
  'event.listMember.organizationType.10': 'Khác',
};
