export default {
  'fields.create': 'Add field',
  'fields.code': 'Field code',
  'fields.name': 'Name of field',
  'fields.description': 'Describe',
  'fields.update': 'Edit field',
  'fields.information': 'Information field',
  'fields.createdAt': 'Creation time',
  'fields.action': 'Act',
  'fields.confirm.title.delete': 'Do you want to delete these areas?',
  'fields.confirm.content.delete': 'Please confirm',
  'fields.careers': 'Branch',
  'fields.fieldRelations': 'Group',
  'fields.fieldRelations.add': "Add children's groups",
  'fields.fieldRelations.title': 'Group',
  'fields.fieldRelations.name': "Child's group name",
  'fields.fieldRelations.description': 'Describe',
  'fields.fieldRelations.name.required': 'Please enter the name of the group',
  'fields.fieldRelations.description.required': 'Please enter the child group information',
  'fields.fieldRelations.code': "Child's group code",
  'fields.fieldRelations.code.required': 'Please enter the child group code',
};
