export default {
  'category.name': 'Danh mục',
  'category.careers.title': 'Ngành',
  'category.careers.heading': 'Danh sách Ngành',
  'category.certificates.title': 'Chứng chỉ',
  'category.certificates.heading': 'Danh sách Chứng chỉ',
  'category.markets.title': 'Thị trường',
  'category.markets.heading': 'Danh sách Thị trường',
  'category.organizations.title': 'Tổ chức',
  'category.organizations.heading': 'Danh sách Tổ chức',
  'category.targetCustomers.title': 'Khách hàng mục tiêu',
  'category.targetCustomers.heading': 'Danh sách Khách hàng mục tiêu',
  'category.enterprises.title': 'Doanh nghiệp',
  'category.enterprises.heading': 'Danh sách Doanh nghiệp',
  'category.fields.title': 'Lĩnh vực',
  'category.fields.heading': 'Danh sách Lĩnh vực',
};
