export default {
  'sponsor.name': 'Tài trợ',
  'sponsor.title': 'Danh sách tài trợ',
  'sponsor.titleDatePicker': 'Thời gian tài trợ',
  'sponsor.create': 'Thêm tài trợ',
  'sponsor.information': 'Chi tiết tài trợ',
  'sponsor.member': 'Tên hội viên',
  'sponsor.product': 'Sản phẩm tài trợ',
  'sponsor.unitPrice': 'Đơn giá (VNĐ)',
  'sponsor.unit': 'Đơn vị',
  'sponsor.quantity': 'Số lượng',
  'sponsor.user': 'Người nhận',
  'sponsor.action': 'Hành động',
  'sponsor.intoMoney': 'Thành tiền (VNĐ)',
  'sponsor.sponsorDate': 'Ngày tài trợ',
  'sponsor.code': 'Mã thành viên',
  'sponsor.emailAddress': 'Email',
  'sponsor.daypay': 'Ngày đóng',
  'sponsor.content': 'Nội dung',
  'sponsor.attach': 'Đính kèm',
  'sponsor.tagName': 'Người tài trợ',
  'sponsor.programCode': 'Mã chương trình',
  'sponsor.delete.title': 'Xóa tài trợ',
  'sponsor.delete.content': 'Bạn có muốn xóa tài trợ này?',
};
