import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { logo } from '@assets/images';
import CONFIG from '@config';
import store from '@core/store/redux';
import { UilAngleLeft, UilAngleRight } from '@iconscout/react-unicons';
import { RootState } from '@modules';
import UserEntity from '@modules/user/entity';
import { Selector } from '@reduxjs/toolkit';
import { privatePage } from '@routers/mainRouter';
import { useAltaIntl } from '@shared/hook/useTranslate';

import MenuCustom from './ItemMenu';

interface IHeaderComponent {
  profile?: UserEntity;
}
const HeaderComponentSelector: Selector<RootState, IHeaderComponent> = (state: RootState) => {
  return {
    profile: state.profile.user,
  };
};

const SiderComponent: React.FC<{
  className: string;
  setClassName: (className: string) => void;
}> = props => {
  const location = useLocation();
  const navigate = useNavigate();
  const { formatMessage } = useAltaIntl();
  const refMouseEnter = useRef<any>();

  const { className, setClassName } = props;
  const [width, setWidth] = useState<string | number>();
  const [collapse, setCollapse] = useState<boolean>(false);
  const onClick = (e: any) => {
    setClassName('sider-component big');
    e.preventDefault();
    e.stopPropagation();
    setCollapse(false);
  };
  const { profile }: any = useSelector(HeaderComponentSelector);

  const handleOnClick = (e: SyntheticEvent) => {
    e.preventDefault();
    if (refMouseEnter.current) {
      clearTimeout(refMouseEnter.current);
    }
    if (collapse === false) {
      setClassName('sider-component');
    } else {
      setClassName('sider-component big');
      setCollapse(false);
    }
    e.stopPropagation();
  };

  const onMouseEnter = e => {
    if (refMouseEnter.current) {
      clearTimeout(refMouseEnter.current);
    }
    refMouseEnter.current = setTimeout(() => {
      onClick(e);
    }, 800);
  };

  const onMouseLeave = () => {
    if (refMouseEnter.current) {
      clearTimeout(refMouseEnter.current);
    }
  };

  useEffect(() => {
    if (className === 'sider-component') {
      setWidth('30rem');
      setCollapse(true);
    } else {
      setWidth('100%');
    }
  }, [className]);

  return (
    <div
      className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        className="icon"
        onClick={handleOnClick}
        onMouseEnter={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {collapse ? <UilAngleRight /> : <UilAngleLeft />}
      </div>
      <div className="mask" style={{ width }}>
        <div className="wrapper-top">
          <div className="wrapper-logo">
            <div className={`logo ${collapse ? 'logo-small' : ''}`}>
              <img src={logo} alt="logo" onClick={() => navigate('/')} />
            </div>
          </div>
          <div className="wrapper-sidebar">
            <MenuCustom listNav={privatePage} location={location.pathname} collapse={collapse} />
          </div>
        </div>

        {/* <div className="wrapper-footer">
          <div className="footer__container">
            <div className="item__left">
              <div
                className="dropdown__profile__img"
                onClick={() => {
                  return navigate('/profile');
                }}
              >
                <img
                  alt="img-avatar"
                  className="img-avatar"
                  src={profile?.avatarPicture || imgAvatar}
                />
              </div>

              <div className="text-avatar">
                <Typography.Paragraph
                  ellipsis={{ rows: 1, tooltip: true }}
                  className="name-avatar text"
                >
                  {profile?.username || '--'}
                </Typography.Paragraph>
                <Typography.Paragraph
                  ellipsis={{ rows: 1, tooltip: true }}
                  className="role-avatar text"
                >
                  {profile?.role?.name || '--'}
                </Typography.Paragraph>
              </div>
            </div>

            <div className="item__right">
              <img
                src={LogoutIcon}
                alt=""
                className="icon-logout"
                onClick={() => {
                  DeleteConfirm({
                    okText: formatMessage('common.ok'),
                    title: formatMessage('common.logout.title'),
                    content: formatMessage('common.logout.content'),
                    handleOk: () => {
                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      store.dispatch(removeProfile()), navigate('/login');
                    },
                  });
                }}
              />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default SiderComponent;
