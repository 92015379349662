import React from 'react';

interface IIcon {
  id: string;
  primary: string;
  secondary: string;
}

const allPersonSvg = (props: IIcon) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path
      d="M20 9.16797C21.5471 9.16797 23.0308 9.78255 24.1248 10.8765C25.2188 11.9705 25.8333 13.4542 25.8333 15.0013C25.8333 16.5484 25.2188 18.0321 24.1248 19.1261C23.0308 20.2201 21.5471 20.8346 20 20.8346C18.4529 20.8346 16.9692 20.2201 15.8752 19.1261C14.7812 18.0321 14.1667 16.5484 14.1667 15.0013C14.1667 13.4542 14.7812 11.9705 15.8752 10.8765C16.9692 9.78255 18.4529 9.16797 20 9.16797ZM8.33333 13.3346C9.26667 13.3346 10.1333 13.5846 10.8833 14.0346C10.6333 16.418 11.3333 18.7846 12.7667 20.6346C11.9333 22.2346 10.2667 23.3346 8.33333 23.3346C7.00725 23.3346 5.73548 22.8079 4.7978 21.8702C3.86012 20.9325 3.33333 19.6607 3.33333 18.3346C3.33333 17.0086 3.86012 15.7368 4.7978 14.7991C5.73548 13.8614 7.00725 13.3346 8.33333 13.3346ZM31.6667 13.3346C32.9927 13.3346 34.2645 13.8614 35.2022 14.7991C36.1399 15.7368 36.6667 17.0086 36.6667 18.3346C36.6667 19.6607 36.1399 20.9325 35.2022 21.8702C34.2645 22.8079 32.9927 23.3346 31.6667 23.3346C29.7333 23.3346 28.0667 22.2346 27.2333 20.6346C28.6865 18.7587 29.3609 16.395 29.1167 14.0346C29.8667 13.5846 30.7333 13.3346 31.6667 13.3346ZM9.16667 30.418C9.16667 26.968 14.0167 24.168 20 24.168C25.9833 24.168 30.8333 26.968 30.8333 30.418V33.3346H9.16667V30.418ZM0 33.3346V30.8346C0 28.518 3.15 26.568 7.41667 26.0013C6.43333 27.1346 5.83333 28.7013 5.83333 30.418V33.3346H0ZM40 33.3346H34.1667V30.418C34.1667 28.7013 33.5667 27.1346 32.5833 26.0013C36.85 26.568 40 28.518 40 30.8346V33.3346Z"
      fill="black"
    />
    <path
      d="M20 9.16797C21.5471 9.16797 23.0308 9.78255 24.1248 10.8765C25.2188 11.9705 25.8333 13.4542 25.8333 15.0013C25.8333 16.5484 25.2188 18.0321 24.1248 19.1261C23.0308 20.2201 21.5471 20.8346 20 20.8346C18.4529 20.8346 16.9692 20.2201 15.8752 19.1261C14.7812 18.0321 14.1667 16.5484 14.1667 15.0013C14.1667 13.4542 14.7812 11.9705 15.8752 10.8765C16.9692 9.78255 18.4529 9.16797 20 9.16797ZM8.33333 13.3346C9.26667 13.3346 10.1333 13.5846 10.8833 14.0346C10.6333 16.418 11.3333 18.7846 12.7667 20.6346C11.9333 22.2346 10.2667 23.3346 8.33333 23.3346C7.00725 23.3346 5.73548 22.8079 4.7978 21.8702C3.86012 20.9325 3.33333 19.6607 3.33333 18.3346C3.33333 17.0086 3.86012 15.7368 4.7978 14.7991C5.73548 13.8614 7.00725 13.3346 8.33333 13.3346ZM31.6667 13.3346C32.9927 13.3346 34.2645 13.8614 35.2022 14.7991C36.1399 15.7368 36.6667 17.0086 36.6667 18.3346C36.6667 19.6607 36.1399 20.9325 35.2022 21.8702C34.2645 22.8079 32.9927 23.3346 31.6667 23.3346C29.7333 23.3346 28.0667 22.2346 27.2333 20.6346C28.6865 18.7587 29.3609 16.395 29.1167 14.0346C29.8667 13.5846 30.7333 13.3346 31.6667 13.3346ZM9.16667 30.418C9.16667 26.968 14.0167 24.168 20 24.168C25.9833 24.168 30.8333 26.968 30.8333 30.418V33.3346H9.16667V30.418ZM0 33.3346V30.8346C0 28.518 3.15 26.568 7.41667 26.0013C6.43333 27.1346 5.83333 28.7013 5.83333 30.418V33.3346H0ZM40 33.3346H34.1667V30.418C34.1667 28.7013 33.5667 27.1346 32.5833 26.0013C36.85 26.568 40 28.518 40 30.8346V33.3346Z"
      fill="url(#paint0_linear_1120_50549)"
    />
    <path
      d="M20 9.16797C21.5471 9.16797 23.0308 9.78255 24.1248 10.8765C25.2188 11.9705 25.8333 13.4542 25.8333 15.0013C25.8333 16.5484 25.2188 18.0321 24.1248 19.1261C23.0308 20.2201 21.5471 20.8346 20 20.8346C18.4529 20.8346 16.9692 20.2201 15.8752 19.1261C14.7812 18.0321 14.1667 16.5484 14.1667 15.0013C14.1667 13.4542 14.7812 11.9705 15.8752 10.8765C16.9692 9.78255 18.4529 9.16797 20 9.16797ZM8.33333 13.3346C9.26667 13.3346 10.1333 13.5846 10.8833 14.0346C10.6333 16.418 11.3333 18.7846 12.7667 20.6346C11.9333 22.2346 10.2667 23.3346 8.33333 23.3346C7.00725 23.3346 5.73548 22.8079 4.7978 21.8702C3.86012 20.9325 3.33333 19.6607 3.33333 18.3346C3.33333 17.0086 3.86012 15.7368 4.7978 14.7991C5.73548 13.8614 7.00725 13.3346 8.33333 13.3346ZM31.6667 13.3346C32.9927 13.3346 34.2645 13.8614 35.2022 14.7991C36.1399 15.7368 36.6667 17.0086 36.6667 18.3346C36.6667 19.6607 36.1399 20.9325 35.2022 21.8702C34.2645 22.8079 32.9927 23.3346 31.6667 23.3346C29.7333 23.3346 28.0667 22.2346 27.2333 20.6346C28.6865 18.7587 29.3609 16.395 29.1167 14.0346C29.8667 13.5846 30.7333 13.3346 31.6667 13.3346ZM9.16667 30.418C9.16667 26.968 14.0167 24.168 20 24.168C25.9833 24.168 30.8333 26.968 30.8333 30.418V33.3346H9.16667V30.418ZM0 33.3346V30.8346C0 28.518 3.15 26.568 7.41667 26.0013C6.43333 27.1346 5.83333 28.7013 5.83333 30.418V33.3346H0ZM40 33.3346H34.1667V30.418C34.1667 28.7013 33.5667 27.1346 32.5833 26.0013C36.85 26.568 40 28.518 40 30.8346V33.3346Z"
      // fill="url(#icon1)"
      fill={`url(#${props.id})`}
    />
    <defs>
      <linearGradient
        id={props.id}
        x1="2.02899"
        y1="31.9451"
        x2="34.4123"
        y2="4.23422"
        gradientUnits="userSpaceOnUse"
      >
        {/* <stop stop-color="#FF6D7D" />
        <stop offset="1" stop-color="#AD001B" /> */}
        <stop stop-color={props.primary} />
        <stop offset="1" stop-color={props.secondary} />
      </linearGradient>
    </defs>
  </svg>
);

export default allPersonSvg;
