import React from 'react';

import { IRouter } from '@routers/interface';

export const routerListExecutiveCommittee: IRouter = {
  path: '/clubs/list-executive-committee/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'clubs.list-executive-committee', //translate here for breadcrumb and sidebar
};
