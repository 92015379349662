export default {
  'memberRank.name-sidebar': 'Hạng thành viên',
  'memberRank.list': 'Danh sách hạng thành viên',
  'memberRank.name': 'Tên hạng',
  'memberRank.desc': 'Mô tả',
  'memberRank.fee': 'Mức hội phí/năm (VNĐ)',
  'memberRank.sponsor': 'Tài trợ tối thiểu (VNĐ)',
  'memberRank.delete': 'Xóa hạng thành viên',
  'memberRank.add': 'Thêm hạng thành viên',
  'memberRank.view': 'Thông tin chi tiết',
  'memberRank.edit': 'Chỉnh sửa thông tin chi tiết',
  'memberRank.label.fee': 'Mức hội phí',
  'memberRank.label.name': 'Tên hạng',
  'memberRank.fee.suffix': 'VNĐ/năm',

  // update 8/8
  'memberRank.code': 'Mã',
  'memberRank.edit-breadcrumb': 'Chỉnh sửa thông tin',
};
