export default {
  'targetCustomers.create': 'Add target customers',
  'targetCustomers.code': 'Target customer code',
  'targetCustomers.name': 'Target customer name',
  'targetCustomers.description': 'Describe',
  'targetCustomers.update': 'Modify target customers',
  'targetCustomers.information': 'Target customer information',
  'targetCustomers.createdAt': 'Creation time',
  'targetCustomers.action': 'Act',
  'targetCustomers.confirm.title.delete': 'Do you want to delete these target customers?',
  'targetCustomers.confirm.content.delete': 'Please confirm',
};
