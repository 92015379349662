/* eslint-disable quotes */
export default {
  'Mes.Users.Password.InValid': 'Invalid username or password',
  'Mes.Users.Username.NotFound': 'Invalid username or password',
  'Mes.Users.SignIn.Successfully': 'Logged in successfully',
  'Mes.Users.Create.Successfully': 'Successful user creation',
  'Mes.Users.Update.Successfully': 'User update successful',
  'Mes.Users.Delete.Successfully': 'Delete user successfully',
  'Mes.Permissions.Code.Required': "Haven't chosen a role yet",
  'Mes.Roles.Create.Successfully': 'Create a successful role',
  'Mes.Roles.Delete.Successfully': 'Delete role successfully',
  'Mes.Roles.Update.Successfully': 'Role update successful',
  'Mes.Roles.Name.Required': 'Invalid role name',
  'Mes.LuckyDraws.Public.Successfully': 'Successful announcement',
  'Mes.LuckyDraws.Id.InValid': 'Failure announcement',
  'Mes.Users.Name.Required': 'Invalid first and last name',
  'Mes.Users.Username.Required': 'Invalid user name',
  'Mes.Users.IsLocked': 'User has been locked',
  'Mes.RedeemCodes.Update.Successfully': 'Status update successful',
};
