export default {
  'cost.name': 'Member name',
  'cost.title': 'Manage membership fees',
  'cost.sidename': 'Membership Fees',
  'cost.create': 'Membership fee payment',
  'cost.total': 'Membership fee (VND)',
  'cost.debt': 'Debt',
  'cost.user': 'Recipient',
  'cost.status': 'Status',
  'cost.action': 'Action',
  'cost.payDate': 'Payment date',
  'cost.information': 'Fee details',
  'cost.code': 'Membership code',
  'cost.emailAddress': 'Email',
  'cost.membershipfee': 'Membership Fee',
  'cost.amount': 'Cost amount (VND)',
  'cost.daypay': 'Pay day',
  'cost.paymentContent': 'Content',
  'cost.attach': 'Attach',
  'cost.tagName': 'Member name',

  'cost.status.active': 'Completed',
  'cost.status.not-active': 'Incomplete',

  'cost.modal.treeTitle1': 'Debt settlement',
  'cost.modal.treeTitle2': 'Close first',

  'cost.tree.title': 'Membership',
  'cost.tree.title.prepay': 'Close first',
  'cost.tree.title.isCostComplete': 'Joining fee',
  'cost.tree.year': 'Select number of years',

  'cost.checkbox.year': 'year',
  'cost.checkbox.feeOneYear': 'One year membership fee is: ',
  'cost.message.year': 'Please enter year',
  'cost.checkbox.total': 'Total',

  'cost.status.param': '{status,select, 1 {Incomplete} 2 {Completed} other {Unknown}}',

  'cost.operationStatus': 'Status',
  'cost.year': 'Year',
  'cost.period': 'Period',
  'cost.tree.error': 'Enter year',
  'cost.error': 'Please enter the fees',
};
