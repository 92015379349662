import React from 'react';

import Icon from '@ant-design/icons';

const nonParticipantSvg = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={33} viewBox="0 0 40 33" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4693 5.28426C12.0629 4.69066 12.0629 3.72825 11.4693 3.13465C10.8757 2.54105 9.91326 2.54105 9.31966 3.13465L7.0927 5.36161L5.01028 3.27918C4.41668 2.68559 3.45427 2.68559 2.86067 3.27918C2.26708 3.87278 2.26708 4.83519 2.86067 5.42879L4.9431 7.51121L2.66932 9.78499C2.07572 10.3786 2.07572 11.341 2.66932 11.9346C3.26292 12.5282 4.22533 12.5282 4.81892 11.9346L7.0927 9.66082L9.51101 12.0791C10.1046 12.6727 11.067 12.6727 11.6606 12.0791C12.2542 11.4855 12.2542 10.5231 11.6606 9.92952L9.24231 7.51121L11.4693 5.28426ZM30.1938 29.0617V32.1982H8.23821V29.0617C8.23821 29.0617 8.23821 22.7887 19.216 22.7887C30.1938 22.7887 30.1938 29.0617 30.1938 29.0617ZM24.7049 14.1633C24.7049 13.0777 24.383 12.0164 23.7798 11.1138C23.1767 10.2112 22.3195 9.50764 21.3165 9.0922C20.3135 8.67676 19.2099 8.56806 18.1452 8.77985C17.0804 8.99164 16.1024 9.5144 15.3348 10.282C14.5671 11.0497 14.0444 12.0277 13.8326 13.0924C13.6208 14.1572 13.7295 15.2608 14.1449 16.2638C14.5604 17.2667 15.2639 18.124 16.1665 18.7271C17.0692 19.3302 18.1304 19.6522 19.216 19.6522C20.6717 19.6522 22.0679 19.0739 23.0972 18.0445C24.1266 17.0151 24.7049 15.619 24.7049 14.1633ZM30.0997 22.7887C31.0638 23.5348 31.8526 24.4831 32.4108 25.5668C32.9689 26.6506 33.2828 27.8435 33.3303 29.0617V32.1982H39.6033V29.0617C39.6033 29.0617 39.6033 23.3689 30.0997 22.7887ZM28.6255 8.67438C27.5461 8.66837 26.4904 8.9911 25.5988 9.59965C26.5514 10.9307 27.0636 12.5265 27.0636 14.1633C27.0636 15.8001 26.5514 17.3959 25.5988 18.7269C26.4904 19.3354 27.5461 19.6582 28.6255 19.6522C30.0813 19.6522 31.4774 19.0739 32.5067 18.0445C33.5361 17.0151 34.1144 15.619 34.1144 14.1633C34.1144 12.7075 33.5361 11.3114 32.5067 10.282C31.4774 9.25267 30.0813 8.67438 28.6255 8.67438Z"
      fill="url(#icon_detail-3)"
    />
    <defs>
      <linearGradient
        id="icon_detail-3"
        x1="2.22412"
        y1="24.9685"
        x2="33.0159"
        y2="-4.25209"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ADFFEB" />
        <stop offset="0.867549" stopColor="#00A6BD" />
      </linearGradient>
    </defs>
  </svg>
);

export default nonParticipantSvg;
