export default {
  'home.name': 'Tài liệu',
  'home.title': 'Tài liệu lưu trữ',
  'home.fileName': 'Tên File',
  'home.fullPath': 'Đường dẫn',
  'home.contentType': 'Loại file',
  'home.createdAt': 'Ngày tạo',
  'home.confirm.title.delete': 'Xóa File',
  'home.confirm.content.delete': 'Bạn đồng ý xóa những file này?',
  'home.create': 'Thêm mới file',
  'home.action': 'Hành Động',
  'home.memberSelect': 'Đối tượng',
  'home.memberSelect-placeholder': 'Chọn đối tượng',
  'home.accepts': 'Đối tượng',
  'home.upload': 'Tải lên file',
  'home.clubs': 'Câu lạc bộ',
  'home.formAdd.code': 'Mã hội viên',
  'home.formAdd.enterpriseName': 'Câu lạc bộ',
  'home.formAdd.emailAddress': 'Email',
  'home.formAdd.type': 'Loại thành viên',
  'home.formAdd.name': 'Tên hội viên',
  'home.maxFile': 'Không thể thêm nhiều hơn cùng lúc nhiều hơn 1 file',
  'home.requiredFile': 'Chọn file tải lên',
  'dashboard.name': 'Trang chủ',
  'home.input.name': 'Tên file',
  'home.placeholder.input.name': 'Nhập tên file',
  'home.validate-file': 'Chỉ được tải lên một tệp duy nhất và dung lượng không vượt quá 60MB.',
  'home.upload.content': 'Hỗ trợ tải lên 1 file duy nhất',
};
