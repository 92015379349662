import React from 'react';

import Icon from '@ant-design/icons';

const allPersonSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="171"
    height="103"
    viewBox="0 0 171 103"
    fill="none"
  >
    <path
      d="M85.5 0C92.1138 0 98.4568 2.61939 103.133 7.28193C107.81 11.9445 110.438 18.2682 110.438 24.8621C110.438 31.4559 107.81 37.7797 103.133 42.4422C98.4568 47.1047 92.1138 49.7241 85.5 49.7241C78.8862 49.7241 72.5432 47.1047 67.8665 42.4422C63.1898 37.7797 60.5625 31.4559 60.5625 24.8621C60.5625 18.2682 63.1898 11.9445 67.8665 7.28193C72.5432 2.61939 78.8862 0 85.5 0ZM35.625 17.7586C39.615 17.7586 43.32 18.8241 46.5263 20.7421C45.4575 30.9 48.45 40.9869 54.5775 48.8717C51.015 55.691 43.89 60.3793 35.625 60.3793C29.956 60.3793 24.5192 58.1341 20.5106 54.1377C16.502 50.1412 14.25 44.7208 14.25 39.069C14.25 33.4171 16.502 27.9967 20.5106 24.0003C24.5192 20.0038 29.956 17.7586 35.625 17.7586ZM135.375 17.7586C141.044 17.7586 146.481 20.0038 150.489 24.0003C154.498 27.9967 156.75 33.4171 156.75 39.069C156.75 44.7208 154.498 50.1412 150.489 54.1377C146.481 58.1341 141.044 60.3793 135.375 60.3793C127.11 60.3793 119.985 55.691 116.423 48.8717C122.635 40.8762 125.518 30.802 124.474 20.7421C127.68 18.8241 131.385 17.7586 135.375 17.7586ZM39.1875 90.569C39.1875 75.8648 59.9212 63.931 85.5 63.931C111.079 63.931 131.812 75.8648 131.812 90.569V103H39.1875V90.569ZM0 103V92.3448C0 82.471 13.4662 74.16 31.7062 71.7448C27.5025 76.5752 24.9375 83.2524 24.9375 90.569V103H0ZM171 103H146.062V90.569C146.062 83.2524 143.497 76.5752 139.294 71.7448C157.534 74.16 171 82.471 171 92.3448V103Z"
      fill="white"
      fill-opacity="0.2"
    />
  </svg>
);
const allPersonLargeIcon = props => <Icon component={allPersonSvg} {...props} />;
export default allPersonLargeIcon;
