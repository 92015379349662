import React from 'react';

import { IRouter } from '@routers/interface';

export const routerEventDesignLandingPage: IRouter = {
  path: '/event/:id/:type/design-landing-page',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'event.designLandingPage',
  // permissionCode: PermissionCode.CLUBS_UPDATE,
};
