export default {
  'forgot.password.title': 'Quên mật khẩu',
  'forgot.password.description': 'Nhập thông tin email để lấy lại mật khẩu mới',
  'forgot.password.notification':
    'Link khôi phục mật khẩu đã được gửi vào email của bạn. Vui lòng kiểm tra email và click vào link đã đính kèm trong mail để bắt đầu đặt lại mật khẩu.',
  'forgot.password.email': 'Email',
  'forgot.password.button.accept': 'Xác nhận',
  'forgot.password.email.not.exit': 'Email không tồn tại',
  'forgot.password.email.invalid': 'Email không phải là một email hợp lệ!',
  'forgot.btn.account': 'Tiếp theo',
  'forgot.passwordConfirm.new': 'Xác nhận mật khẩu mới',
  'forgot.password.new': 'Mật khẩu mới',
  'forgot.button.save': 'Lưu',
  'forgot.invalid_password': 'Mật khẩu không đúng định dạng',
  'require.password_notMatch': 'Mật khẩu không khớp',
  'forgot.recoveryCode': 'Nhập mã xác nhận',
  'forgot.btn.next': 'Tiếp theo',
};
