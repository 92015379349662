export default {
  'organizations.create': 'Add organization',
  'organizations.code': 'Organization code',
  'organizations.name': 'Organization Name',
  'organizations.description': 'Describe',
  'organizations.update': 'Organization editing',
  'organizations.information': 'Organizational information',
  'organizations.createdAt': 'Creation time',
  'organizations.action': 'Act',
  'organizations.confirm.title.delete': 'Do you want to delete these organizations?',
  'organizations.confirm.content.delete': 'Please confirm',
};
