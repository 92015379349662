import React from 'react';
import * as Icon from 'react-feather';

import { IRouter } from '@routers/interface';

const mainRoute = 'roles';

export const routerRolesAdd: IRouter = {
  path: `/${mainRoute}/add`,
  loader: React.lazy(() => import('./Page/index')),
  exact: true,
  permissionCode: 'RolesCreate',
  name: 'roles.add.name', //translate here for breadcrumb and sidebar
};

export const routerRolesEdit: IRouter = {
  path: `/${mainRoute}/edit/:id`,
  loader: React.lazy(() => import('./Page/index')),
  exact: true,
  permissionCode: 'RolesUpdate',
  name: 'roles.edit.name', //translate here for breadcrumb and sidebar
};

export const routerRolesInfo: IRouter = {
  path: `/${mainRoute}/info/:id`,
  loader: React.lazy(() => import('./Page/index')),
  exact: true,
  permissionCode: 'RolesView',
  name: 'roles.info.name', //translate here for breadcrumb and sidebar
};

export const routerRoles: IRouter = {
  path: `/${mainRoute}`,
  loader: React.lazy(() => import('./index')),
  exact: true,
  permissionCode: 'RolesSearch',
  name: 'role.name', //translate here for breadcrumb and sidebar
  icon: <Icon.Sliders />,
};
