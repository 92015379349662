import React from 'react';
import * as Icon from 'react-feather';

import { IRouter } from '@routers/interface';

export const routerContacts: IRouter = {
  path: '/setting/contacts',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'contacts.name', //translate here for breadcrumb and sidebar
  icon: <Icon.Phone />,
};
