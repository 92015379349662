import React from 'react';

import Icon from '@ant-design/icons';

const Feedback = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
  >
    <g clip-path="url(#clip0_2862_23659)">
      <path
        d="M6.71063 13.5L3 16.5V4.5C3 4.30109 3.07902 4.11032 3.21967 3.96967C3.36032 3.82902 3.55109 3.75 3.75 3.75H15.75C15.9489 3.75 16.1397 3.82902 16.2803 3.96967C16.421 4.11032 16.5 4.30109 16.5 4.5V12.75C16.5 12.9489 16.421 13.1397 16.2803 13.2803C16.1397 13.421 15.9489 13.5 15.75 13.5H6.71063Z"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.5 13.5V17.25C7.5 17.4489 7.57902 17.6397 7.71967 17.7803C7.86032 17.921 8.05109 18 8.25 18H17.2894L21 21V9C21 8.80109 20.921 8.61032 20.7803 8.46967C20.6397 8.32902 20.4489 8.25 20.25 8.25H16.5"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2862_23659">
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

const FeedbackIcon = props => <Icon component={Feedback} {...props} />;

export default FeedbackIcon;
