import httpRepository from '@core/repository/http';
import User from '@modules/user/entity';

const register = (payload: any) => {
  return httpRepository.execute({
    path: '/auth/register',
    method: 'post',
    payload,
  });
};

const forgotPassword = (payload: any) => {
  return httpRepository.execute({
    path: '/api/Users/ForgotPassword',
    method: 'post',
    payload,
  });
};

const CheckRecoveryToken = (code: any) => {
  return httpRepository.execute({
    path: `api/Users/CheckRecoveryToken/${code}`,
    method: 'get',
  });
};

const updatePassword = (payload: any) => {
  return httpRepository.execute({
    path: '/api/Users/ChangePassword',
    method: 'put',
    payload,
    showSuccess: false,
    showError: false,
  });
};

export interface ILoginDTO {
  userName: string;
  password: string;
}

const login = (payload: ILoginDTO) => {
  return httpRepository.execute({
    path: '/api/Users/SignIn',
    method: 'post',
    payload,
    showSuccess: false,
    showError: false,
  });
};

const resetToken = (payload: { refreshToken: string }) => {
  return httpRepository.execute({
    path: '/api/Users/Refresh',
    method: 'post',
    payload,
    showSuccess: false,
  });
};

const logout = () => {
  return httpRepository.execute({
    path: '/api/Users/Signout',
    method: 'get',
    showSuccess: false,
  });
};

const resetPassword = (payload: any) => {
  return httpRepository.execute({
    path: 'api/Users/ResetForgotPassword',
    method: 'put',
    payload,
  });
};

const getProfile = () => {
  return httpRepository.execute({
    path: '/api/Users/Profile',
    method: 'get',
    showSuccess: false,
    convert: res => {
      return new User(res);
    },
  });
};

const updateProfile = (payload: any) => {
  return httpRepository.execute({
    path: '/api/Users/Profile',
    method: 'put',
    showSuccess: true,
    config: { isFormData: true },
    payload,
    convert: res => {
      return new User(res);
    },
  });
};

const uploadAvatar = (payload: any) => {
  return httpRepository.execute({
    path: 'api/Users',
    method: 'put',
    payload,
  });
};

const updateProfileUser = (id: any, payload: any) => {
  return httpRepository.execute({
    path: `api/Users/${id}`,
    method: 'put',
    config: { isFormData: true },
    payload,
  });
};

export default {
  register,
  login,
  logout,
  resetToken,
  resetPassword,
  forgotPassword,
  CheckRecoveryToken,
  updatePassword,
  getProfile,
  uploadAvatar,
  updateProfile,
  updateProfileUser,
};
