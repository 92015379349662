import React from 'react';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerFormExecutiveCommittee: IRouter = {
  path: '/member-and-partner/executive-committee/add',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'executiveCommittee.add', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.EXECUTIVE_COMMITTEES_CREATE,
};

export const routerFormExecutiveCommitteeInfo: IRouter = {
  path: '/member-and-partner/executive-committee/:type/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'executiveCommittee.view', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.EXECUTIVE_COMMITTEES_VIEW,
};
