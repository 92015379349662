export default {
  'certificates.create': 'Thêm chứng chỉ',
  'certificates.code': 'Mã chứng chỉ',
  'certificates.name': 'Tên chứng chỉ',
  'certificates.description': 'Mô tả',
  'certificates.update': 'Chỉnh sửa chứng chỉ',
  'certificates.information': 'Thông tin chứng chỉ',
  'certificates.createdAt': 'Thời gian tạo',
  'certificates.action': 'Hành động',
  'certificates.confirm.title.delete': 'Bạn có muốn xóa những chứng chỉ này?',
  'certificates.confirm.content.delete': 'Bạn vui lòng xác nhận lại',
};
