import React from 'react';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerFormMemberPersonal: IRouter = {
  path: '/member-and-partner/member-personal/add',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'memberPersonal.add', //translate here for breadcrumb and sidebar
  permissionCode: [PermissionCode.MEMBERS_CREATE, PermissionCode.CLUBS_CREATE_MEMBER],
};

export const routerFormMemberPersonalInfo: IRouter = {
  path: '/member-and-partner/member-personal/:type/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'memberPersonal.view', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.MEMBERS_VIEW,
};
