export default {
  'news.nameSidebar': 'Bản tin',
  'news.info': 'Chi tiết tin',
  'news.edit': 'Chỉnh sửa tin',
  'news.add': 'Thêm tin mới',
  'news.title': 'Tiêu đề',
  'news.title-pl': 'Nhập tiêu đề',
  'news.short-desc': 'Mô tả ngắn',
  'news.short-desc-pl': 'Nhập mổ tả ngắn',
  'news.view-with': 'Hiển thị với',
  'news.cover': 'Ảnh bìa',
  'news.cover-pl': 'Tải ảnh',
  'news.cover-desc': 'Mô tả ảnh',
  'news.cover-desc-pl': 'Nhập mô tả',
  'news.view-with-pl': 'Chọn đối tượng được hiển thị',
  'news.description': 'Mô tả ngắn',
  'news.documentAccepts': 'Hiển thị với',
  'news.creator': 'Người đăng bài',
  'news.createdAt': 'Thời gian đăng bài',
  'news.action': 'Hành động',
  'news.delete.title': 'Xóa bản tin',
  'news.delete.content': 'Bạn có muốn xóa tin đã chọn?',
  'news.add-image': 'Thêm hình ảnh',
  'news.all-clubs': 'Tất cả câu lạc bộ',
};
