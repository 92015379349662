import { english, vietnam } from '@assets/images';
import ISelect from '@core/select';

export const LANGUAGE: ISelect<string>[] = [
  { value: 'vi', label: 'VNM', icon: vietnam },
  { value: 'en', label: 'ENG', icon: english },
];

export const allSelect: ISelect = { label: 'common.all', value: undefined };

export const idClubAdmin = '6bec45d3-83a0-4160-865d-2ccfb128e306';

export const idAdministrator = '79775bc2-1899-459b-a9eb-0e26822ae40e';

export const onlyIdClubAdmin = '8b7120ad-d5ab-401f-8b2f-6732ea4e5576';

const CONFIG = {
  API_BASE_URL: process.env.API_BASE_URL,
  GAME_URL: 'https://mizuku.dev.altasoftware.vn',
  APP_NAME: process.env.APP_NAME || 'AltaFrontend',
  LOGIN_PAGE: process.env.SSO_PAGE || 'https://hca-sso.dev.altasoftware.vn',
  CLIENT_ID: process.env.CLIENT_ID || '10c47ebe99a3f80332b50df420691633',
  TINY_KEY: process.env.TINY_KEY || 'p0wepyb5vhie1fq4i2f3ok37mdvx2dncg3m1262f1gvkdnqz',
  PREVIEW_PAGE: process.env.PREVIEW_PAGE || 'http://localhost:3000',
  STRAPI_PAGE: process.env.STRAPI_PAGE || 'http://localhost:2700',
};

export default CONFIG;
