export default {
  'memberAndPartner.name': 'Hội viên và đối tác',
  'memberAndPartner.title': 'Nhập',
  'memberAndPartner.buttonSubmit': 'Thêm',
  'memberAndPartner.buttonEdit': 'Sửa',
  'fees.done': 'Hoàn thành',
  'fees.not-done': 'Chưa hoàn thành',
  'historySponsors.view': 'Tài chính',
  'historyFees.view': 'Hội phí',

  'memberAndPartner.info': 'Chi tiết',
  'memberAndPartner.edit': 'Chỉnh sửa',
  'memberAndPartner.delete': 'Xóa',
  'memberAndPartner.histoty-sponsors': 'Tài trợ',
  'memberAndPartner.histoty-cost': 'Hội phí',

  'memberAndPartner.active': 'Đang hoạt động',
  'memberAndPartner.not-active': 'Ngưng hoạt động',
  'member.connectInfos.btn-add': 'Thêm người phụ trách',

  // Executive Committee
  'executiveCommittee.title': 'Ban chấp hành',
  'executiveCommittee.heading': 'Danh sách ban chấp hành',
  'executiveCommittee.add': 'Thêm ban chấp hành mới',
  'executiveCommittee.code': 'Mã hội viên',
  'executiveCommittee.name': 'Tên hội viên',
  'executiveCommittee.emailAddress': 'Email',
  'executiveCommittee.workUnit': 'Tên đơn vị',
  'executiveCommittee.position': 'Chức vụ',
  'executiveCommittee.status': 'Tình trạng hoạt động',
  'executiveCommittee.action': 'Hành động',
  'executiveCommittee.view': 'Chi tiết ban chấp hành',
  'executiveCommittee.edit': 'Chỉnh sửa ban chấp hành',
  'executiveCommittee.delete.title': 'Xóa ban chấp hành',
  'executiveCommittee.delete.content': 'Bạn có muốn xóa ban chấp hành này?',
  'executiveCommittee.non-delete.title': 'Không thể xóa ban chấp hành',
  'executiveCommittee.non-delete.content': 'Không thể xóa ban chấp hành đang hoạt động',

  'executiveCommittee.label.code': 'Mã đăng nhập',
  'executiveCommittee.placeholder.code': 'Nhập Mã đăng nhập',
  'executiveCommittee.label.idCard': 'Mã thẻ',
  'executiveCommittee.placeholder.idCard': 'Nhập mã thẻ',
  'executiveCommittee.label.name': 'Họ và tên',
  'executiveCommittee.placeholder.name': 'Nhập họ và tên',
  'executiveCommittee.label.dayOfBirth': 'Ngày sinh',
  'executiveCommittee.placeholder.dayOfBirth': 'Nhập ngày sinh',
  'executiveCommittee.label.gender': 'Giới tính',
  'executiveCommittee.gender.male': 'Nam',
  'executiveCommittee.gender.female': 'Nữ',
  'executiveCommittee.label.phoneNumber': 'Số điện thoại',
  'executiveCommittee.placeholder.phoneNumber': 'Nhập số điện thoại',
  'executiveCommittee.label.emailAddress': 'Email',
  'executiveCommittee.placeholder.emailAddress': 'Nhập địa chỉ email',
  'executiveCommittee.label.avatar': 'Ảnh đại diện',
  'executiveCommittee.label.workUnit': 'Tên đơn vị',
  'executiveCommittee.placeholder.workUnit': 'Nhập tên đơn vị',
  'executiveCommittee.label.position': 'Chức vụ',
  'executiveCommittee.placeholder.position': 'Nhập chức vụ',
  'executiveCommittee.label.title': 'Chức danh',
  'executiveCommittee.placeholder.title': 'Nhập Chức danh',
  'executiveCommittee.label.prorogue': 'Nhiệm kì',
  'executiveCommittee.placeholder.prorogue': 'Nhập Nhiệm kì',

  'executiveCommittee.label.detailLogin': 'Thông tin đăng nhập',
  'executiveCommittee.placeholder.detailLogin': 'Mã hội viên',
  'executiveCommittee.label.status': 'Tình trạng hoạt động',
  'executiveCommittee.label.status.active': 'Đang hoạt động',
  'executiveCommittee.label.status.not-active': 'Ngưng hoạt động',
  'executiveCommittee.label.structure': 'Cơ cấu',
  'executiveCommittee.placeholder.structure': 'Chọn cơ cấu',
  'executiveCommittee.structure.executiveCommittee': 'Ban chấp hành',
  'executiveCommittee.structure.standingCommittee': 'Ban thường vụ',
  'executiveCommittee.validator.structure': 'Chọn cơ cấu',

  // Form Executive Committee
  'executiveCommittee.header': 'Thêm ban chấp hành',
  'executiveCommittee.main.title-1': '1. Thông tin cá nhân',
  'executiveCommittee.main.title-2': '2. Thông tin đơn vị',
  'executiveCommittee.main.title-3': '3. Chức vụ hội',
  'executiveCommittee.main.title-4': '5. Thông tin tài khoản',

  // Member
  'member.title': 'Hội viên doanh nghiệp',
  'member.heading': 'Danh sách hội viên doanh nghiệp',
  'member.code': 'Mã hội viên',
  'member.enterpriseName': 'Tên hội viên',
  'member.fieldId': 'Lĩnh vực',
  'member.memberFields': 'Lĩnh vực',
  'member.marketId': 'Thị trường',
  'member.targetCustomer': 'Khách hàng mục tiêu',
  'member.enterpriseScale': 'Quy mô doanh nghiệp',
  'member.status': 'Tình trạng hoạt động',
  'member.action': 'Hành động',
  'member.field': 'Lĩnh vực',
  'member.market': 'Thị trường',
  'member.headOfficeAddress': 'Địa chỉ',
  'member.type': 'Loại hội viên',
  // 'member.targetCustomer': 'Khách hàng mục tiêu',
  'member.view': 'Chi tiết hội viên',
  'member.edit': 'Chỉnh sửa hội viên',
  'member.delete.title': 'Xóa hội viên',
  'member.delete.content': 'Bạn có muốn xóa hội viên này?',
  'member.non-delete.title': 'Không thể xóa hội viên',
  'member.non-delete.content': 'Không thể xóa hội viên đang hoạt động',

  'member.add': 'Thêm hội viên mới',
  'member.label.code': 'Mã đăng nhập',
  'member.placeholder.code': 'Nhập Mã đăng nhập',
  'member.label.idCard': 'Mã thẻ',
  'member.placeholder.idCard': 'Nhập Mã thẻ',
  'member.label.enterpriseName': 'Tên doanh nghiệp (Tiếng Việt)',
  'member.placeholder.enterpriseName': ' Nhập tên doanh nghiệp',
  'member.label.enterpriseEnglishName': 'Tên doanh nghiệp (Tiếng Anh)',
  'member.placeholder.enterpriseEnglishName': ' Nhập tên doanh nghiệp',
  'member.label.enterpriseAcronymsName': 'Tên doanh nghiệp (Tên viết tắt)',
  'member.placeholder.enterpriseAcronymsName': ' Nhập tên doanh nghiệp',
  'member.label.headOfficeAddress': 'Địa chỉ trụ sở chính',
  'member.placeholder.headOfficeAddress': 'Nhập địa chỉ trụ sở chính',
  'member.label.transactionOfficeAddress': 'Địa chỉ văn phòng giao dịch',
  'member.placeholder.transactionOfficeAddress': 'Nhập địa chỉ văn phòng giao dịch',
  'member.label.taxCode': 'Mã số thuế',
  'member.placeholder.taxCode': 'Nhập mã số thuế',
  'member.label.phoneNumber': 'Số điện thoại',
  'member.placeholder.phoneNumber': 'Nhập số điện thoại',
  'member.label.website': 'Website',
  'member.placeholder.website': 'Nhập website',
  'member.label.fanpage': 'Fanpage',
  'member.placeholder.fanpage': 'Nhập fanpage',
  'member.label.birthday': 'Ngày thành lập',
  'member.placeholder.birthday': 'Nhập ngày thành lập',
  'member.label.charterCapital': 'Vốn điều lệ',
  'member.placeholder.charterCapital': 'Nhập số vốn điều lệ',
  'member.label.turnover': 'Doanh thu trước khi gia nhập',
  'member.placeholder.turnover': 'Nhập doanh thu năm 2021',
  'member.label.type': 'Loại hội viên',
  'member.placeholder.type': 'Chọn Loại hội viên',
  'member.label.fieldRelationIds': 'Lĩnh vực',
  'member.placeholder.fieldRelationIds': 'Chọn lĩnh vực',
  'member.label.marketId': 'Thị trường',
  'member.placeholder.marketId': 'Chọn thị trường',
  'member.label.targetCustomerId': 'Khách hàng mục tiêu',
  'member.placeholder.targetCustomerId': 'Chọn khách hàng mục tiêu',
  'member.label.enterpriseScale': 'Quy mô doanh nghiệp',

  'member.enterpriseScale.value-0': '50 - 100 người',
  'member.enterpriseScale.value-1': '100 - 200 người',
  'member.enterpriseScale.value-2': '200 - 500 người',
  'member.enterpriseScale.value-3': 'Trên 500 người',
  'member.label.certificateId': 'Chứng chỉ',
  'member.placeholder.certificateId': 'Chọn chứng chỉ',
  'member.label.prize': 'Giải thưởng',
  'member.placeholder.prize': 'Nhập giải thưởng',
  'member.label.certificateOfMerit': 'Bằng khen, giấy khen',
  'member.placeholder.certificateOfMerit': 'Nhập bằng khen, giấy khen',
  'member.label.leaderInfo.name': 'Họ và tên',
  'member.placeholder.leaderInfo.name': 'Nhập họ và tên',
  'member.label.leaderInfo.dateOfBirth': 'Ngày sinh',
  'member.placeholder.leaderInfo.dateOfBirth': 'Nhập ngày sinh',
  'member.label.leaderInfo.position': 'Chức vụ',
  'member.placeholder.leaderInfo.position': 'Nhập chức vụ',
  'member.label.leaderInfo.phoneNumber': 'Số điện thoại',
  'member.placeholder.leaderInfo.phoneNumber': 'Nhập số điện thoại',
  'member.label.leaderInfo.gender': 'Giới tính',
  'member.label.leaderInfo.gender.male': 'Nam',
  'member.label.leaderInfo.gender.female': 'Nữ',
  'member.label.leaderInfo.emailAddress': 'Email liên hệ trực tiếp',
  'member.placeholder.leaderInfo.emailAddress': 'Nhập địa chỉ email',
  'member.label.connectInfos.name': 'Họ và tên',
  'member.placeholder.connectInfos.name': 'Nhập họ và tên',
  'member.label.connectInfos.position': 'Chức vụ',
  'member.placeholder.connectInfos.position': 'Nhập chức vụ',
  'member.label.connectInfos.phoneNumber': 'Số điện thoại',
  'member.placeholder.connectInfos.phoneNumber': 'Nhập số điện thoại',
  'member.label.connectInfos.gender': 'Giới tính',
  'member.label.connectInfos.gender.male': 'Nam',
  'member.label.connectInfos.gender.female': 'Nữ',
  'member.label.connectInfos.emailAddress': 'Email liên hệ trực tiếp',
  'member.placeholder.connectInfos.emailAddress': 'Nhập địa chỉ email',
  'member.label.detailSingin': 'Thông tin đăng nhập',
  'member.placeholder.detailSingin': 'Thông tin đăng nhập',
  'member.label.careers': 'Ngành',
  'member.placeholder.careers': 'Chọn ngành',
  'member.placeholder.type.memberUnit': 'Hội viên chính thức',
  'member.placeholder.type.memberLinked': 'Hội viên liên kết',
  'member.label.clubs': 'Câu lạc bộ',
  'member.placeholder.clubs': 'Chọn câu lạc bộ',

  // Form Member
  'formMember.header': 'Thêm hội viên chính thức',
  'formMember.main.title-1': '1. Thông tin cá nhân',
  'formMember.main.title-2': '2. Ngành - Lĩnh vực',
  'formMember.main.title-3': '3. Thị trường',
  'formMember.main.title-4': '4. Khách hàng mục tiêu',
  'formMember.main.title-5': '5. Quy mô doanh nghiệp',
  'formMember.main.title-6': '6. Chứng chỉ và giải thưởng',
  'formMember.main.title-7': '7. Lãnh đạo đơn vị',
  'formMember.main.title-8': '8. Phụ trách kết nối',
  'formMember.main.title-9': '9. Câu lạc bộ',
  'formMember.main.title-11': '11. Thông tin tài khoản',
  'formMember.main.title-11.1': '10. Thông tin tài khoản',

  // Partner
  'partner.title': 'Đối tác',
  'partner.heading': 'Danh sách đối tác',
  'partner.code': 'Mã đối tác',
  'partner.enterpriseName': 'Tên đối tác',
  'partner.headOfficeAddress': 'Địa chỉ',
  'partner.phoneNumber': 'Điện thoại',
  'partner.organizationId': 'Tổ chức',
  'partner.enterpriseId': 'Doanh nghiệp',
  'partner.status': 'Tình trạng hoạt động',
  'partner.action': 'Hành động',
  'partner.view': 'Chi tiết đối tác',
  'partner.edit': 'Chỉnh sửa đối tác',
  'partner.add': 'Thêm đối tác mới',
  'partner.delete.title': 'Xóa đối tác',
  'partner.delete.content': 'Bạn có muốn xóa đối tác?',
  'partner.non-delete.title': 'Không thể xóa đối tác',
  'partner.non-delete.content': 'Không thể xóa đối tác đang hoạt động',
  'partner.partnerSource': 'Phân loại',
  'partner.partnerName': 'Tên đối tác',
  'partner.type': 'Loại đối tác',
  'partner.enterprise': 'Đối tác doanh nghiệp',
  'partner.personal': 'Đối tác cá nhân',
  'partner.vietnam': 'Việt Nam',
  'partner.national': 'Quốc tế',

  'partner.label.code': 'Mã đối tác',
  'partner.placeholder.code': 'Nhập mã đối tác',
  'partner.label.idCard': 'Mã thẻ',
  'partner.placeholder.idCard': 'Nhập mã thẻ',
  'partner.label.enterpriseName': 'Tên doanh nghiệp (Tiếng việt)',
  'partner.placeholder.enterpriseName': 'Nhập tên doanh nghiệp',
  'partner.label.enterpriseEnglishName': 'Tên doanh nghiệp (Tiếng Anh)',
  'partner.placeholder.enterpriseEnglishName': 'Nhập tên doanh nghiệp',
  'partner.label.enterpriseAcronymsName': 'Tên doanh nghiệp (Tên viết tắt)',
  'partner.placeholder.enterpriseAcronymsName': 'Nhập tên doanh nghiệp',
  'partner.label.partnerSource': 'Phân loại',
  'partner.partnerSource.domestic': 'Việt Nam',
  'partner.partnerSource.foreign': 'Quốc tế',
  'partner.label.headOfficeAddress': 'Địa chỉ trụ sở chính',
  'partner.placeholder.headOfficeAddress': 'Nhập địa chỉ',
  'partner.label.transactionOfficeAddress': 'Địa chỉ văn phòng giao dịch',
  'partner.placeholder.transactionOfficeAddress': 'Nhập địa chỉ văn phòng giao dịch',
  'partner.label.taxCode': 'Mã số thuế',
  'partner.placeholder.taxCode': 'Nhập mã số thuế',
  'partner.label.phoneNumber': 'Số điện thoại',
  'partner.placeholder.phoneNumber': 'Nhập số điện thoại',
  'partner.label.website': 'Website',
  'partner.placeholder.website': 'Nhập website',
  'partner.label.organizationId': 'Tổ chức',
  'partner.placeholder.organizationId': 'Chọn tổ chức',
  'partner.label.enterpriseId': 'Doanh nghiệp',
  'partner.placeholder.enterpriseId': 'Chọn doanh nghiệp',
  'partner.label.leaderInfo.name': 'Họ và tên',
  'partner.placeholder.leaderInfo.name': 'Nhập họ và tên',
  'partner.label.leaderInfo.dateOfBirth': 'Ngày sinh',
  'partner.placeholder.leaderInfo.dateOfBirth': 'Nhập ngày sinh',
  'partner.label.leaderInfo.position': 'Chức vụ',
  'partner.placeholder.leaderInfo.position': 'Nhập chức vụ',
  'partner.label.leaderInfo.phoneNumber': 'Số điện thoại',
  'partner.placeholder.leaderInfo.phoneNumber': 'Nhập số điện thoại',
  'partner.label.leaderInfo.gender': 'Giới tính',
  'partner.label.leaderInfo.gender.male': 'Nam',
  'partner.label.leaderInfo.gender.female': 'Nữ',
  'partner.label.leaderInfo.emailAddress': 'Email liên hệ trực tiếp',
  'partner.placeholder.leaderInfo.emailAddress': 'Nhập địa chỉ email',
  'partner.label.connectInfos.name': 'Họ và tên',
  'partner.placeholder.connectInfos.name': 'Nhập họ và tên',
  'partner.label.connectInfos.position': 'Chức vụ',
  'partner.placeholder.connectInfos.position': 'Chức vụ',
  'partner.label.connectInfos.phoneNumber': 'Số điện thoại',
  'partner.placeholder.connectInfos.phoneNumber': 'Nhập số điện thoại',
  'partner.label.connectInfos.gender': 'Giới tính',
  'partner.label.connectInfos.gender.male': 'Nam',
  'partner.label.connectInfos.gender.female': 'Nữ',
  'partner.label.connectInfos.emailAddress': 'Email liên hệ trực tiếp',
  'partner.placeholder.connectInfos.emailAddress': 'Nhập địa chỉ email',
  'partner.label.type': 'Phân loại',
  'partner.type.enterprise': 'Đối tác doanh nghiệp',
  'partner.type.personal': 'Đối tác cá nhân',
  'partner.label.name': 'Họ và tên',
  'partner.placeholder.name': 'Nhập họ và tên',
  'partner.label.position': 'Chức vụ',
  'partner.placeholder.position': 'Nhập chức vụ',
  'partner.label.emailAddress': 'Email',
  'partner.placeholder.emailAddress': 'Nhập email',
  'partner.label.workUnit': 'Đơn vị',
  'partner.placeholder.workUnit': 'Nhập đơn vị',

  // Form Partner
  'formPartner.header': 'Thêm đối tác',
  'formPartner.main-title': 'Loại đối tác',

  'formPartner.main-title-1': '1. Thông tin doanh nghiệp',
  'formPartner.main-title-1-personal': '1. Thông tin cá nhân',

  'formPartner.main-title-2': '2. Tổ chức & Doanh nghiệp',
  'formPartner.main-title-2-personal': '2. Ngành và Lĩnh vực',

  'formPartner.main-title-3': '3. Lãnh đạo đơn vị',
  'formPartner.main-title-4': '4. Phụ trách kết nối',
  'formPartner.main-title-5': '5. Thông tin tài khoản',
  'formPartner.main-title-5-personal': '3. Thông tin tài khoản',
  'formPartner.main-title-9-personal': '9. Thông tin tài khoản',

  // History Fees
  'hitory-fees.name': 'Tên thành viên',
  'hitory-fees.email': 'Email',
  'hitory-fees.operationStatus': 'Tình trạng hoạt động',
  'hitory-fees.total': 'Tổng hội phí đã đóng',
  'hitory-fees.year': 'Năm',
  'hitory-fees.payDate': 'Ngày đóng hội phí',
  'hitory-fees.paid': 'Số tiền phải đóng (VNĐ)',
  'hitory-fees.paymentContent': 'Nội dung',
  'hitory-fees.userReceive': 'Người nhận',
  'hitory-fees.status': 'Tình trạng',
  'hitory-fees.attachPath': 'Đính kèm',
  'fees.status.param':
    '{status,select, 1 {Chưa hoàn thành} 2 {Đã hoàn thành} other {Không xác định}}',
  'history-fees.total': 'Tổng hội phí đã đóng',

  // History Sponsors
  'history-sponsors.sponsorDate': 'Ngày tài trợ',
  'history-sponsors.content': 'Nội dung',
  'history-sponsors.product': 'Sản phẩm tài trợ',
  'history-sponsors.unitPrice': 'Đơn vị',
  'history-sponsors.quantity': 'Số lượng',
  'history-sponsors.intoMoney': 'Thành tiền (VNĐ)',
  'history-sponsors.user': 'Người nhận',
  'history-sponsors.attachPath': 'Đính kèm',
  'history-sponsors.total': 'Tổng tài trợ',

  // Member Personal
  'memberPersonal.title': 'Hội viên cá nhân',
  'memberPersonal.heading': 'Danh sách hội viên cá nhân',
  'memberPersonal.view': 'Chi tiết hội viên cá nhân',
  'memberPersonal.add': 'Thêm hội viên cá nhân',
  'memberPersonal.edit': 'Chỉnh sửa hội viên cá nhân',
  'formMemberPersonal.main.title-1': '1. Thông tin cá nhân',
  'memberPersonal.label.code': 'Mã đăng nhập',
  'memberPersonal.placeholder.code': 'Nhập mã đăng nhập',
  'memberPersonal.label.idCard': 'Mã thẻ',
  'memberPersonal.placeholder.idCard': 'Nhập mã thẻ',
  'memberPersonal.label.name': 'Họ và tên',
  'memberPersonal.placeholder.name': 'Nhập họ và tên',
  'memberPersonal.label.position': 'Chức vụ',
  'memberPersonal.placeholder.position': 'Nhập chức vụ',
  'memberPersonal.label.dayOfBirth': 'Ngày sinh',
  'memberPersonal.placeholder.dayOfBirth': 'Nhập ngày sinh',
  'memberPersonal.label.gender': 'Giới tính',
  'memberPersonal.label.gender.male': 'Nam',
  'memberPersonal.label.gender.female': 'Nữ',
  'memberPersonal.label.phoneNumber': 'Số điện thoại',
  'memberPersonal.placeholder.phoneNumber': 'Nhập số điện thoại',
  'memberPersonal.label.emailAddress': 'Email',
  'memberPersonal.placeholder.emailAddress': 'Nhập địa chỉ email',
  'formMemberPersonal.main.title-2': '2. Ngành - Lĩnh vực',
  'memberPersonal.label.careers': 'Ngành',
  'memberPersonal.placeholder.careers': 'Chọn ngành',
  'memberPersonal.label.fieldRelationIds': 'Lĩnh vực',
  'memberPersonal.placeholder.fieldRelationIds': 'Chọn lĩnh vực',
  'formMemberPersonal.main.title-3': '3. Thông tin tài khoản',
  'memberPersonal.label.detailSingin': 'Tên đăng nhập',

  // Member personal table
  'member-personal.code': 'Mã hội viên',
  'member-personal.name': 'Họ và tên',
  'member-personal.emailAddress': 'Email',
  'member-personal.career': 'Ngành',
  'member-personal.field': 'Lĩnh vực',
  'member-personal.placeholder.field': 'Lĩnh vực',
  'member-personal.action': 'Hành động',

  'member.provinces': 'Chọn tỉnh / thành phố',
  'member.districts': 'Chọn quận / huyện',
  'member.communes': 'Chọn phường / xã',

  'member.label.dayOfBirth': 'Ngày thành lập',
  'member.label.headOfficeAddressRoad': 'Địa chỉ chi tiết',
  'member.label.transactionOfficeAddressRoad': 'Địa chỉ chi tiết',
  'member.label.districts': 'quận huyện',
  'member.label.communes': 'phường xã',
  'formMember.main.title-1-unit': '1. Thông tin đơn vị',

  'member.label.emailAddress': 'Email',
  'formPartner.main-title-4-personal': '4. Thông tin tài khoản',
  'formPartner.main-title-4.1-personal': '3. Thông tin tài khoản',
  'formPartner.main-title-3-personal': '3. Ban chấp hành',
  'member-personal.yes': 'Có',
  'member-personal.no': 'Không',
  'executiveCommittee.label.canConfirmActivity': 'Quyền điểm danh',

  // update on 10/10/2023
  'memberPersonal.label.workUnit': 'Đơn vị',

  // update on 08/08/2024
  'member.label.rank': 'Hạng thành viên',
  'member.placeholder.rank': 'Vui lòng chọn hạng thành viên',
  'member.label.fee': 'Mức hội phí',
  'member.label.period': 'Thời gian tham gia',
  'member.label.period-1': 'Kỳ 1',
  'member.label.period-2': 'Kỳ 2',
  'formMember.main.title-10': '10. Hạng và hội phí',
  'formMember.main.title-10.1': '9. Hạng và hội phí',
  'executiveCommittee.main.title-5': '4. Hạng', ///
};
