import React from 'react';

import { IRouter } from '@routers/interface';

export const routerHistorySponsors: IRouter = {
  path: '/member-and-partner/history-sponsors/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'historySponsors.view', //translate here for breadcrumb and sidebar
};
