export default {
  'cost.title': 'Quản lý hội phí',
  'cost.create': 'Đóng hội phí',
  'cost.sidename': 'Hội phí',
  'cost.name': 'Tên hội viên',
  'cost.total': 'Mức hội phí (VNĐ)',
  'cost.debt': 'Số tiền phải thu',
  'cost.user': 'Người nhận',
  'cost.status': 'Trạng thái',
  'cost.action': 'Hành động',
  'cost.payDate': 'Ngày đóng hội phí',
  'cost.information': 'Chi tiết hội phí',
  'cost.code': 'Mã hội viên',
  'cost.emailAddress': 'Email',
  'cost.membershipfee': 'Hội phí',
  'cost.amount': 'Số tiền đóng (VNĐ)',
  'cost.daypay': 'Ngày đóng',
  'cost.paymentContent': 'Nội dung',
  'cost.attach': 'Đính kèm',
  'cost.tagName': 'Tên hội viên',

  'cost.status.active': 'Đã hoàn thành',
  'cost.status.not-active': 'Chưa hoàn thành',

  'cost.modal.treeTitle1': 'Tất toán nợ',
  'cost.modal.treeTitle2': 'Đóng trước',

  'cost.tree.title': 'Hội phí',
  'cost.tree.title.prepay': 'Đóng trước',
  'cost.tree.title.isCostComplete': 'Lệ phí gia nhập',
  'cost.tree.year': 'Chọn số năm:',

  'cost.checkbox.year': 'năm',
  'cost.checkbox.feeOneYear': 'Hội phí 1 năm là: ',
  'cost.message.year': 'Nhập năm',
  'cost.checkbox.total': 'Tổng',

  'cost.status.param':
    '{status,select, 1 {Chưa hoàn thành} 2 {Đã hoàn thành} other {Không xác định}}',

  'cost.operationStatus': 'Trạng thái',
  'cost.year': 'Năm',
  'cost.period': 'Kỳ',
  'cost.tree.error': 'Nhập năm',
  'cost.error': 'Vui lòng nhập hội phí',
};
