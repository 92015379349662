export default {
  'careers.create': 'More',
  'careers.code': 'Industry code',
  'careers.name': 'Industry name',
  'careers.description': 'Describe',
  'careers.update': 'Industry editing',
  'careers.information': 'Industry information',
  'careers.createdAt': 'Creation time',
  'careers.action': 'Act',
  'careers.confirm.title.delete': 'Do you want to delete these industries?',
  'careers.confirm.content.delete': 'Please confirm',
};
