import React from 'react';

import { IRouter } from '@routers/interface';

export const routerEventListMember: IRouter = {
  path: '/event/:id/list-member/:tab?',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'event.list-member', //translate here for breadcrumb and sidebar
  // permissionCode: PermissionCode.CLUBS_UPDATE,
};
