export default {
  'common.page.notfound': 'Opps! Page not found',
  'common.404error': 'Error 404',
  'common.login': 'Re-login',
  'common.404.welcome':
    'Hello {name}! The site you quote is currently unavailable.Please try again after admin granted access',
  'common.404note': 'Sorry, we counldn’t find the page you are looking for',
  'common.error.authorization':
    'Currently your account has not been assigned to access.please contact{email} for assistance.',
  'common.error.title.authorization': 'Confirm account authorization!',
};
