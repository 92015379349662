import React from 'react';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerDetailFeedbackPage: IRouter = {
  path: '/feedback/:type/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'feedback.info', //translate here for breadcrumb and sidebar
  widthFull: true,
  permissionCode: PermissionCode.FEEDBACKS_VIEW_DETAIL_PAGE,
};
