import React from 'react';
import * as Icon from 'react-feather';

import { IRouter } from '@routers/interface';
import { routerCategory } from '@view/Category/router';
import { routerContacts } from '@view/Contacts/router';
import { routerMemberRank } from '@view/MemberRank/router';
import { routerRoles } from '@view/Roles/router';
import { routerUser } from '@view/User/router';

export const routerSetting: IRouter = {
  path: null,
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'setting.name', //translate here for breadcrumb and sidebar
  menu: {
    activePath: 'setting',
    icon: <Icon.Settings />,
  },
  routes: [routerMemberRank, routerCategory, routerRoles, routerUser, routerContacts],
};
