import React from 'react';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerMemberRankAdd: IRouter = {
  path: '/member-rank/:type',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'memberRank.view', //translate here for breadcrumb and sidebar
  //   permissionCode: PermissionCode.CLUBS_CREATE,
};

export const routerMemberRankEdit: IRouter = {
  path: '/member-rank/:type/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'memberRank.view', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.RANKS_UPDATE,
};
