import moment from 'moment';

import RoleEntity from '@modules/roles/entity';

export class AccountPermissionEntity {
  username: string = '';

  emailAddress: string = '';

  phoneNumber: string = '';

  name: string = '';

  dayOfBirth: string = '';

  gender: any;

  avatarPath: any;

  operationStatus?: number | string;

  sponsors: any;

  notifications?: Array<any>;

  fees: any;

  roleId: string = '';

  role: any;

  id: string = '';

  createdAt: string = '';

  constructor(permission: Partial<AccountPermissionEntity>) {
    if (!permission) {
      return;
    }
    Object.assign(this, permission);
  }

  static createlistPermisison(list?: Array<Partial<AccountPermissionEntity>>) {
    if (list === undefined) {
      return undefined;
    }
    return list.map(ll => {
      return new AccountPermissionEntity(ll);
    });
  }
}
class UserEntity {
  username: string = '';

  emailAddress: string = '';

  name: string = '';

  userPermissions: AccountPermissionEntity[] = [];

  dayOfBirth: any = null;

  avatarPath: any = null;

  status: number = 1;

  roleId?: string = '';

  role?: RoleEntity;

  id: string = '';

  createdAt: string = '';

  updateAt: string = '';

  constructor(user?: Partial<UserEntity>) {
    if (!user) {
      return;
    }
    Object.assign(this, user);
    this.userPermissions =
      AccountPermissionEntity.createlistPermisison(user?.userPermissions) || [];
    this.createdAt = user?.createdAt ? moment(user?.createdAt).format('DD/MM/YYYY HH:mm:ss') : '';

    this.updateAt = user?.updateAt ? moment(user?.createdAt).format('DD/MM/YYYY HH:mm:ss') : '';
    this.dayOfBirth = user?.dayOfBirth ? moment(user.dayOfBirth) : undefined;
  }

  static createArrayUser(arrUser: Array<Partial<UserEntity>>): Array<UserEntity> {
    if (!arrUser) {
      return [];
    }
    return arrUser.map(x => new UserEntity(x));
  }
}

export default UserEntity;
