import React from 'react';

import ListMemberIcon from '@assets/icon/ListMemberIcon';
import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerMemberAndPartnerTabs: IRouter = {
  path: '/member-and-partner/:tab',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'memberAndPartner.name', //translate here for breadcrumb and sidebar
  permissionCode: [
    PermissionCode.MEMBERS_SEARCH,
    PermissionCode.EXECUTIVE_COMMITTEES_SEARCH,
    PermissionCode.PARTNERS_SEARCH,
    PermissionCode.REPRESENTS_SEARCH,
  ],
  generatePath: () => 'member-and-partner/checkPermission', //default url
  activePath: 'member-and-partner',
  icon: <ListMemberIcon />,
};
