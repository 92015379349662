import React from 'react';
import * as Icon from 'react-feather';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerCategory: IRouter = {
  path: '/category/:tab',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'category.name', //translate here for breadcrumb and sidebar
  generatePath: () => '/category/checkPermission', //default url
  icon: <Icon.Menu />,
  activePath: 'category',
  permissionCode: [
    PermissionCode.CAREERS_SEARCH,
    PermissionCode.CERTIFICATES_SEARCH,
    PermissionCode.FIELDS_SEARCH,
    PermissionCode.ENTERPRISES_SEARCH,
    PermissionCode.MARKETS_SEARCH,
    PermissionCode.ORGANIZATIONS_SEARCH,
    PermissionCode.TARGET_CUSTOMERS_SEARCH,
  ],
};
