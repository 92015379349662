import React from 'react';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerFormClubsPartner: IRouter = {
  path: '/clubs/list-members/form-partner/:type/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'partner.add', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.PARTNERS_CREATE,
};

export const routerFormClubsPartnerInfo: IRouter = {
  path: '/clubs/list-members/form-partner/:type/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'partner.view', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.PARTNERS_UPDATE,
};
