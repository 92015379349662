import React from 'react';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerFormClubsExecutiveCommitteeClubs: IRouter = {
  path: '/member-and-partner/form-executive-committee/:type/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'executiveCommittee.add', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.REPRESENTS_CREATE,
};

export const routerFormClubsExecutiveCommitteeClubInfo: IRouter = {
  path: '/member-and-partner/form-executive-committee/:type/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'executiveCommittee.view', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.REPRESENTS_VIEW,
};
