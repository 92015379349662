export default {
  'approveMember.sideBar': 'Danh sách yêu cầu sửa đổi',
  'approveMember.code': 'Mã hội viên',
  'approveMember.name': 'Tên hội viên',
  'approveMember.clubId': 'Câu lạc bộ',
  'approveMember.target': 'Đối tượng',
  'approveMember.dateReceive': 'Ngày nhận yêu cầu',
  'approveMember.status': 'Trạng thái',
  'approveMember.approve': 'Đã phê duyệt',
  'approveMember.replied': 'Đã phản hồi',
  'approveMember.pending': 'Chờ phê duyệt',
  'approveMember.status.param':
    '{status,select, 1 {Chờ phê duyệt} 2 {Đã phê duyệt} 3 {Đã phản hồi} other {Không xác định}}',
  'approveMember.before': 'Thông tin chi tiết trước sửa đổi',
  'approveMember.after': 'Thông tin chi tiết sau sửa đổi',
  'approveMember.modal.title': 'Phê duyệt',
  'approveMember.modal.send': 'Gửi',
  'approveMember.modal.content': 'Xác nhận duyệt các thay đổi đã chọn',
  'approveMember.modal.note.title': 'Thông tin thay đổi chưa phù hợp',
  'approveMember.modal.item.label': 'Nội dung',
  'approveMember.modal.noted': 'Vui lòng kiểm tra lại trạng thái của các yêu cầu đã chọn',
  'approveMember.rejectReason': 'Ghi chú',
};
