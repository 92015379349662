import React from 'react';

import ListModificationIcon from '@assets/icon/ListModificationIcon';
import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerApproveMember: IRouter = {
  path: '/member-and-partner/approve-member',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'approveMember.sideBar', //translate here for breadcrumb and sidebar
  icon: <ListModificationIcon />,
  permissionCode: PermissionCode.MEMBER_MODIFICATION_SEARCH,
};
