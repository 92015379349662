import React from 'react';
import * as Icon from 'react-feather';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerEvent: IRouter = {
  path: '/event',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'event.name', //  here for breadcrumb and sidebar
  menu: {
    icon: <Icon.Activity />,
  },
  permissionCode: PermissionCode.ACTIVITIES_SEARCH,
  // permissionCode: 'ALLOW',
};
