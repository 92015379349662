export default {
  'category.name': 'Category',
  'category.careers.title': 'Branch',
  'category.careers.heading': 'List of branches',
  'category.certificates.title': 'Certificate',
  'category.certificates.heading': 'List of certificates',
  'category.markets.title': 'Market',
  'category.markets.heading': 'Market list',
  'category.organizations.title': 'Organization',
  'category.organizations.heading': 'Organization list',
  'category.targetCustomers.title': 'Targeted customer',
  'category.targetCustomers.heading': 'List of target customers',
  'category.enterprises.title': 'Enterprise',
  'category.enterprises.heading': 'Enterprise list',
  'category.fields.title': 'Field',
  'category.fields.heading': 'List of fields',
};
