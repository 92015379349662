export default {
  'careers.create': 'Thêm ngành',
  'careers.code': 'Mã ngành',
  'careers.name': 'Tên ngành',
  'careers.description': 'Mô tả',
  'careers.update': 'Chỉnh sửa ngành',
  'careers.information': 'Thông tin ngành',
  'careers.createdAt': 'Thời gian tạo',
  'careers.action': 'Hành động',
  'careers.confirm.title.delete': 'Bạn có muốn xóa những ngành này?',
  'careers.confirm.content.delete': 'Bạn vui lòng xác nhận lại',
};
