import React from 'react';
import * as Icon from 'react-feather';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerUser: IRouter = {
  path: '/user',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'user.name', //translate here for breadcrumb and sidebar
  icon: <Icon.User />,
  permissionCode: PermissionCode.USERS_SEARCH,
};
