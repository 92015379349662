export default {
  'markets.create': 'Thêm thị trường',
  'markets.code': 'Mã thị trường',
  'markets.name': 'Tên thị trường',
  'markets.description': 'Mô tả',
  'markets.update': 'Chỉnh sửa thị trường',
  'markets.information': 'Thông tin thị trường',
  'markets.createdAt': 'Thời gian tạo',
  'markets.action': 'Hành động',
  'markets.confirm.title.delete': 'Bạn có muốn xóa những thị trường này?',
  'markets.confirm.content.delete': 'Bạn vui lòng xác nhận lại',
};
