import React from 'react';

import { FeedbackIcon } from '@assets/icon';
import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerFeedback: IRouter = {
  path: '/feedback',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'feedback.name', //translate here for breadcrumb and sidebar
  menu: {
    icon: <FeedbackIcon />,
  },
  permissionCode: PermissionCode.FEEDBACKS_SEARCH,
};
