export default {
  'contacts.name': 'Contact',
  'contacts.title': 'Contact',
  'contacts.switchboard': 'Switchboard',
  'contacts.website': 'Website',
  'contacts.fanpage': 'Fanpage',
  'contacts.email': 'Email',
  'contacts.address': 'Address',
  'contacts.longitude': 'Longitude',
  'contacts.latitude': 'Latitude',
  'contact-page.message.email': 'Please enter email',
  'contact-page.message.switchboard': 'Please enter switchboard',
  'contact-page.message.format.switchboard': 'Switchboard is malformed',
  'contact-page.message.format.email': 'Email is malformed',
};
