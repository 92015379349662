/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/named */
import '@shared/assets/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import '@styles/styles.scss';

import { ConfigProvider } from 'antd';
import lodash from 'lodash';
import React, { memo, useEffect, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { WarningOutlined } from '@ant-design/icons';
import CONFIG from '@config';
import store from '@core/store/redux';
import locale from '@locale/index';
import authenticationPresenter from '@modules/authentication/presenter';
import { removeProfile, TokenSelector } from '@modules/authentication/profileStore';
import { LanguageSelector } from '@modules/setting/settingStore';
import PrivatePage from '@routers/component/PrivatePage';
import { publicRouter } from '@routers/index';
import { ConfirmReStart } from '@shared/components/ConfirmReStart';
import { useSingleAsync } from '@shared/hook/useAsync';
import { toSearch } from '@shared/hook/useQueryParams';
import { useAltaIntl } from '@shared/hook/useTranslate';

import PublicPage from '../routers/component/PublicPage';
import { routerPageError } from './PageError/router';

const MainView = memo(({ statusLogin }: { statusLogin: boolean }) => {
  const { formatMessage } = useAltaIntl();
  const navigate = useNavigate();
  const locationDom = useLocation();

  const [searchParams] = useSearchParams();
  const token = useSelector(TokenSelector);

  const loginByAccount = useSingleAsync(authenticationPresenter.login);
  const params = useMemo(() => Object.fromEntries(searchParams.entries()), [searchParams]);

  useEffect(() => {
    if (locationDom.pathname !== '/' && publicRouter.some(i => locationDom.pathname === i.path)) {
      return;
    }
    if (params && params?.code) {
      loginByAccount
        .execute({
          code: params?.code,
          redirectUri: params?.redirectUris || params?.redirectUri,
        })
        .then(() => {
          authenticationPresenter.getProfile().then(res => {
            if (lodash.isEmpty(res.role?.rolePermissions)) {
              navigate(routerPageError.path);
            }
            navigate(locationDom.pathname);
          });
        })
        .catch(() => {
          ConfirmReStart({
            modifiers: 'red',
            icon: <WarningOutlined />,
            hiddenCancel: true,
            okText: formatMessage('common.logout'),
            title: formatMessage('profile.code.title'),
            content: formatMessage('profile.code.content'),
            handleOk: () => {
              store.dispatch(removeProfile());
              window.location.href = CONFIG.LOGIN_PAGE;
            },
          });
        });
    } else if (lodash.isEmpty(token)) {
      window.location.href =
        CONFIG.LOGIN_PAGE +
        '?' +
        toSearch({
          redirectUris: `${window.location.href.split('?')[0]}${
            window.location.href.split('?')[0].includes('#') ? '' : '#/'
          }`,
          clientId: CONFIG.CLIENT_ID,
          // scope: CONFIG.SCOPE,
        });
    }
  }, [params]);
  return <>{statusLogin ? <PrivatePage /> : <PublicPage />}</>;
});

// For Test
const App: React.FC = () => {
  const token = useSelector(TokenSelector);
  const { language } = useSelector(LanguageSelector);
  const memoLangData = useMemo(() => {
    return locale[language];
  }, [language]);

  return (
    <IntlProvider locale={language} messages={memoLangData}>
      <ConfigProvider locale={memoLangData}>
        <MainView statusLogin={!lodash.isEmpty(token)} />
      </ConfigProvider>
    </IntlProvider>
  );
};

export default App;
