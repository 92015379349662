export default {
  'enterprises.create': 'More businesses',
  'enterprises.code': 'Business Code',
  'enterprises.name': "Company's name",
  'enterprises.description': 'Describe',
  'enterprises.update': 'Enterprise editing',
  'enterprises.information': 'Business information',
  'enterprises.createdAt': 'Creation time',
  'enterprises.action': 'Act',
  'enterprises.confirm.title.delete': 'Do you want to delete these businesses?',
  'enterprises.confirm.content.delete': 'Please confirm',
};
