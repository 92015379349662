import React from 'react';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerFormApproveMember: IRouter = {
  path: '/member-and-partner/approve-member/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'approveMember.form.nameSidebar', //translate here for breadcrumb and sidebar
  //   permissionCode: PermissionCode.CLUBS_UPDATE,
  activePath: '/approve-member',
  permissionCode: PermissionCode.MEMBER_MODIFICATION_VIEW,
};
