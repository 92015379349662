export default {
  'common.page.notfound': 'Chúng tôi không tìm thấy trang này',
  'common.404error': '404',
  'common.login': 'Đăng nhập lại',
  'common.404.welcome':
    'xin chào {name}! Trang bạn yên cầu hiện tại không thể truy cập. Vui lòng thử lại sau khi admin đã cấp quyền truy cập',
  'common.404note': 'Xin lỗi, chúng tôi không thể tìm thấy trang bạn đang tìm kiếm',
  'common.error.authorization':
    'Hiện tài khoản của bạn chưa được phân quyền truy cập. Vui lòng liên hệ {email} để được hỗ trợ.',
  'common.error.title.authorization': 'Xác nhận phân quyền tài khoản!',
};
