import React from 'react';
import * as Icon from 'react-feather';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerMemberRank: IRouter = {
  path: '/member-rank',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'memberRank.name-sidebar', //translate here for breadcrumb and sidebar
  icon: <Icon.BarChart2 />,
  permissionCode: PermissionCode.RANKS_VIEW,
};
